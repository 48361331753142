import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import OrdersTable from './OrdersTable'
import _ from 'lodash'
import OrderUtilities from '../common/OrderUtilities';
import { performSortForDispatchedOrders } from '../common/OrderUtilities';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import DriverUtilities from '../common/DriverUtilities'
import DialogForSequencingDispatches from './DialogForSequencingDispatches';

DispatchedOrders.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  customerRefs: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  pricing: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  rowColorMap: PropTypes.object.isRequired
}

const columnsForTable = [
  'ref',
  'dispatchSequence',
  'driver',
  'subdivision',
  'preloaded',
  'orderDate',
  'preloadedDate',
  'anticipatedDeliveryDate',
  'requestedDeliveryDate',
  'notes',
  'priority',
  'deliveryAddress',
  'city',
  'loads',
  'heavy',
  'material',
  'purpose',
  'contact',
  'contactEmail'
]

export default function DispatchedOrders(props) {

  const [showSequence, setShowSequence] = useState(false)

  const driverWithTruckListRef = useRef(undefined)

  const orderUtilitiesRef = useRef(undefined)
  const orderQueryRef = useRef(undefined)
  const [driversOrdersWithRefs, setDriversOrdersWithRefs] = useState(undefined)
  const [value, setValue] = useState('All')
  const driverListRef = useRef(undefined)

  useEffect(() => {
    const tempDrivers = props.drivers.filter(driver => {
      return driver.currentlyDriving
    })
    driverListRef.current = DriverUtilities.performDefaultSortForDriverNames(
      tempDrivers.map(driver => {
        return driver.name.trim()
      })
    )
    driverWithTruckListRef.current = DriverUtilities.sortDriverObjectsByTruck(tempDrivers)
  }, [props.drivers])

  useEffect(() => {

    orderQueryRef.current = props.db.collection('orders')
      .where('dispatched', '==', true)
      .where('delivered', '==', false)
      .where('cancelled', '==', false)
      .where('held', '==', false)

    const areaMap = _.keyBy(props.cities, (o) => { return o.area.trim() })
    const citiesMap = _.keyBy(props.cities, (o) => { return o.name.trim() })
    const subdivisionsMap = _.keyBy(props.subdivisions, (o) => { return o.trim() })
    const materialMap = _.keyBy(props.materials, (o) => { return o.name.trim() })
    const purposeMap = _.keyBy(props.purposes, (o) => { return o.name.trim() })
  
    orderUtilitiesRef.current = new OrderUtilities(
      props.firebase, 
      props.db,
      areaMap,
      props.customerRefs,
      citiesMap,
      subdivisionsMap,
      materialMap,
      purposeMap,
      props.pricing
    )

    let ordersUnsubscribe = orderQueryRef.current
      .onSnapshot(querySnapshot => {
        let tempOrdersWithRefs = querySnapshot.docs.map(orderRef => {
          return orderUtilitiesRef.current.convertOrderForOrderTable(orderRef)
        })  
        tempOrdersWithRefs = performSortForDispatchedOrders(tempOrdersWithRefs)
        const driverOrdersMap = {}
        driverOrdersMap['All'] = []
        driverListRef.current.forEach(driver => {
          driverOrdersMap[driver.trim()] = []
        })
        tempOrdersWithRefs.forEach(orderWithRef => {
          driverOrdersMap['All'].push(orderWithRef)
          driverOrdersMap[orderWithRef.driver.trim()].push(orderWithRef)
        }) 
        setDriversOrdersWithRefs(driverOrdersMap)

      })
    return () => {
      ordersUnsubscribe()
    }
  }, [props.firebase,
    props.db,
    props.query,
    props.customerRefs, 
    props.materials, 
    props.purposes, 
    props.cities, 
    props.pricing,
    props.actions,
    props.subdivisions]
  )
  
  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  const sequenceDispatches = () => {
    setShowSequence(true)
  }

  const onCloseSequenceDispatches = () => {
    setShowSequence(false)
  }

  if (!driversOrdersWithRefs) {
    return (<div></div>)
  }

  if (showSequence) {
    return (
      <DialogForSequencingDispatches orderUtilities={orderUtilitiesRef.current} ordersToSequence={driversOrdersWithRefs[value]} driver={value} onClose={onCloseSequenceDispatches} rowColorMap={props.rowColorMap}/>
      )
  }

  return (
    <div>
      <Tabs value={value} onChange={handleTabChange} >
      <Tab key={'All'} label={`All (${driversOrdersWithRefs['All'].length})`} value={'All'}/>
        {driverWithTruckListRef.current.map(driver => (
          <Tab key={driver.name.trim()} label={`${driver.truck ? driver.truck.trim() : ''} - ${driver.name.split(' ')[0]} (${driversOrdersWithRefs[driver.name.trim()].length})`} value={driver.name.trim()}/>
        ))}
      </Tabs>
      { value !== 'All' 
        ? <Button color="primary" onClick={sequenceDispatches}>Sequence</Button>
        : null 
      }
      <OrdersTable firebase={props.firebase}
        db={props.db}
        title={`Dispatched Orders - ${value}`}
        columns={columnsForTable}
        actions={['ready', 'deliver', 'cancel', 'hold']}
        customerRefs={props.customerRefs}
        materials={props.materials}
        purposes={props.purposes}
        cities={props.cities}
        subdivisions={props.subdivisions}
        pricing={props.pricing}
        drivers={props.drivers}
        rowColorMap={props.rowColorMap}
        ordersWithRefs={driversOrdersWithRefs[value]}
        orderUtilities={orderUtilitiesRef.current}
        tabValue={value}
      />
    </div>
  )

}