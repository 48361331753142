import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types';
import { TextField, Button, DialogContent, DialogActions, Dialog, DialogTitle, Box, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import OrderUtilities, { performSortForReadyOrders } from '../common/OrderUtilities';
import DateUtilities from '../common/DateUtilities';
import OrdersTable from './OrdersTable'
import DriverUtilities from '../common/DriverUtilities'
import DialogForDispatchWithInstructions from './DialogForDispatchWithInstructions';

BatchDispatch.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  drivers: PropTypes.array.isRequired,
  customerRefs: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired, 
  pricing: PropTypes.array.isRequired,
  rowColorMap: PropTypes.object.isRequired,
  pits: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
}

export default function BatchDispatch(props) {

  const onClose = props.onClose

  const [open] = useState(props.open)
  const [driver, setDriver] = useState(null)
  const [areas, setAreas] = useState([])
  const [material, setMaterial] = useState('All')
  const [canBatchDispatch, setCanBatchDispatch] = useState(false)
  const [dispatchWithInstructions, setDispatchWithInstructions] = useState(false)

  const ordersFromTableToDispatch = useRef([])

  const orderUtilitiesRef = useRef(undefined)
  const dateUtilities = useRef(new DateUtilities())
  const orderQueryRef = useRef(undefined)
  const areaListRef = useRef(undefined)
  const materialListRef = useRef(undefined)
  const driversListRef = useRef(undefined)
  const driversMapRef = useRef(undefined)
  const [areaOrdersWithRefs, setAreaOrdersWithRefs] = useState(undefined)
  const [ordersToDisplay, setOrdersToDisplay] = useState([])

  const columnsForTable = [
    'ref',
    'customer',
    'subdivision',
    'orderDate',
    'requestedDeliveryDate',
    'notes',
    'area',
    'deliveryAddress',
    'city',
    'loads',
    'heavy',
    'material',
    'purpose',
    'price',
    'contact',
    'priority'
  ]
  
  useEffect(() => {
    const activeDrivers = DriverUtilities.limitDriversToActiveDrivers(props.drivers)
    driversMapRef.current = _.keyBy(activeDrivers, (driver) => { return driver.name.trim()})
    driversListRef.current = DriverUtilities.performDefaultSortForDriverNames(
      activeDrivers.map(driver => {
        return driver.name.trim()
      })
    )

  }, [props.drivers])

  useEffect(() => {

    orderQueryRef.current = props.db.collection('orders')
      .where('ready', '==', true)
      .where('dispatched', '==', false)
      .where('delivered', '==', false)
      .where('cancelled', '==', false)
      .where('held', '==', false)

    let hideDate = dateUtilities.current.addBusinessDays(new Date(new Date().toDateString()), 2)
    const areaMap = _.keyBy(props.cities, (o) => { return o.area.trim() })
    const citiesMap = _.keyBy(props.cities, (o) => { return o.name.trim() })
    const subdivisionsMap = _.keyBy(props.subdivisions, (o) => { return o.trim() })
    const materialMap = _.keyBy(props.materials, (o) => { return o.name.trim() })
    const purposeMap = _.keyBy(props.purposes, (o) => { return o.name.trim() })

    orderUtilitiesRef.current = new OrderUtilities(
      props.firebase, 
      props.db,
      areaMap,
      props.customerRefs,
      citiesMap,
      subdivisionsMap,
      materialMap,
      purposeMap,
      props.pricing
    )

    areaListRef.current = _.uniq(props.cities.map(city => {
      return city.area.trim()
    }).sort((areaA, areaB) => {
      return areaA < areaB ? -1 : 1
    }))

    materialListRef.current = props.materials.map(material => {
      return material.name
    })
    materialListRef.current.unshift('All')
  
    let ordersUnsubscribe = orderQueryRef.current
      .onSnapshot(querySnapshot => {
        let tempOrdersWithRefs = querySnapshot.docs.map(orderRef => {
          return orderUtilitiesRef.current.convertOrderForOrderTable(orderRef)
        })
        tempOrdersWithRefs = performSortForReadyOrders(tempOrdersWithRefs)

        const tempReadyOrdersWithRefs = tempOrdersWithRefs.filter(order => {
          if (order.dispatched) return false
          if ((order.requestedDeliveryDate instanceof Date) &&
            (order.requestedDeliveryDate > hideDate)) {
              return false
            }
          return true
        })
        const areaOrdersMap = {}
        areaListRef.current.forEach(area => {
          areaOrdersMap[area.trim()] = []
        })
        tempReadyOrdersWithRefs.forEach(orderWithRef => {
          areaOrdersMap[orderWithRef.area.trim()].push(orderWithRef)
        }) 
        setAreaOrdersWithRefs(areaOrdersMap)
      })
    return () => {
      ordersUnsubscribe()
    }
  }, [props.firebase,
    props.db,
    props.customerRefs, 
    props.materials, 
    props.purposes, 
    props.cities, 
    props.pricing,
    props.subdivisions]
  )

  useEffect(() => {
    if (areaOrdersWithRefs && areas.length > 0) {
      let combinedAreasOrders = areas.reduce((collector, area) => {
        return collector.concat(areaOrdersWithRefs[area])
      }, [])
      let tempOrdersToDisplay = combinedAreasOrders.filter(order => {
        if (!material) return true
        if (material === 'All') return true
        return order.material === material
      })
      setOrdersToDisplay(tempOrdersToDisplay)
    } else {
      setOrdersToDisplay([])
    }
  }, [areas, areaOrdersWithRefs, material])

  useEffect(() => {
    if (!driver) return
    if (driversMapRef.current[driver].area) {
      setAreas([driversMapRef.current[driver].area.trim()])
    }
  }, [driver])

  const close = () => {
    onClose()
  }

  const onCancel = () => {
    close()
  }

  const handleDriverChange = (event, value) => {
    setDriver(value)
    determineCanBatchDispatch()
  }

  const handleAreaChange = (event) => {
    let tempAreas = areas.map(area => area)
    if (event.target.checked) {
      tempAreas.push(event.target.name)
    } else {
      tempAreas = tempAreas.filter(area => {
        return area === event.target.name ? false : true
      })
    }      
    setAreas(tempAreas)
    determineCanBatchDispatch()
  }

  const handleMaterialChange = (event, value) => {
    setMaterial(value ? value.trim() : null)
    determineCanBatchDispatch()
  }

  const handleSelectionChange = (rows) => {
    ordersFromTableToDispatch.current = [...rows]
    determineCanBatchDispatch()
  }

  const onDispatch = async () => {
    if (!ordersFromTableToDispatch.current) {
      alert("Please select orders to dispatch")
      return
    }
    setDispatchWithInstructions(true)
  }

  const onOrderUpdate = (newData) => {
    ordersFromTableToDispatch.current = undefined;
  }

  function processResultOfDispatch(completed) {
    setDispatchWithInstructions(false)
    if (completed) {
      ordersFromTableToDispatch.current = undefined;
    }
  }

  function determineCanBatchDispatch() {

    let result = true
    if (!driver) result = false
    if (areas.length === 0) result = false
    if ((!ordersFromTableToDispatch.current) || (ordersFromTableToDispatch.current.length < 1)) result = false
    setCanBatchDispatch(result)

  }

  if (!areaListRef.current && !driversListRef.current) {
    return '';
  }

  if (dispatchWithInstructions) {
    return <DialogForDispatchWithInstructions
      firebase={props.firebase}
      db={props.db}
      rowColorMap={props.rowColorMap}
      onClose={processResultOfDispatch}
      orderUtilities={orderUtilitiesRef}
      ordersToDispatch={ordersFromTableToDispatch.current}
      driver={driversMapRef.current[driver]}
      pits={props.pits}
    >
    </DialogForDispatchWithInstructions>
  }

  return (
    <Dialog open={open} onClose={close} fullScreen>
      <DialogTitle id="simple-dialog-title">Dispatch Orders for Tomorrow</DialogTitle>
      <DialogContent>
        <div style={{ width: '100%' }}>
          <Box width={1/2} component="div" display="inline" >
            <Autocomplete
              id="drivers"
              size="small"
              fullWidth={true}
              required={true}
              options={driversListRef.current}
              onChange={handleDriverChange}
              value={driver}
              renderInput={(params) => <TextField {...params} label="Driver Name" InputLabelProps={{ shrink: true }} />}
            />  
          </Box>  
        </div>
        <div style={{ width: '100%' }}>
          <Box width={1/2} component="div" display="inline" >
            <Autocomplete
              id="materials"
              size="small"
              fullWidth={true}
              required={true}
              options={materialListRef.current}
              autoSelect={true}
              autoComplete={true}
              autoHighlight={true}
              openOnFocus={true}
              selectOnFocus={true}      
              onChange={handleMaterialChange}
              value={material}
              renderInput={(params) => <TextField {...params} label="Material" InputLabelProps={{ shrink: true }} />}
            />  
          </Box>  
        </div>
        <FormGroup row="True">
          {areaListRef.current.map(areaInstance => {
            return (
              <FormControlLabel
                control={
                  <Checkbox checked={areas.includes(areaInstance)} onChange={handleAreaChange} name={areaInstance} />
                }
                label={areaInstance}
              />
            )
            })
          }
        </FormGroup>        
        <OrdersTable
          firebase={props.firebase}
          db={props.db}
          title={"Orders to Dispatch"}
          columns={columnsForTable}
          actions={['split']}
          customerRefs={props.customerRefs}
          materials={props.materials}
          purposes={props.purposes}
          cities={props.cities}
          subdivisions={props.subdivisions}
          pricing={props.pricing}
          drivers={props.drivers}
          rowColorMap={props.rowColorMap}
          ordersWithRefs={ordersToDisplay}
          orderUtilities={orderUtilitiesRef.current}
          onSelectionChange={handleSelectionChange}
          selection={true}
          // isReadOnly={true}
          paging={false}
          onUpdate={onOrderUpdate}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>Cancel</Button>
        <Button color="primary" onClick={onDispatch} disabled={!canBatchDispatch}>Dispatch</Button>
      </DialogActions>

    </Dialog>
  )
}
