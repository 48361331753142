export default class DateUtilities {
  addBusinessDays = (startDate, daysToAdd) => {
    let businessDaysFound = 0
    let targetDate = new Date(startDate.toDateString())
    while (businessDaysFound < daysToAdd) {
        targetDate.setDate(targetDate.getDate() + 1)
        if (targetDate.getDay() === 0 || targetDate.getDay() === 7) {
          continue
        }
        businessDaysFound += 1
    }
    return targetDate
  }
}