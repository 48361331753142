import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField } from '@material-ui/core'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Image from "material-ui-image";

DialogForPitTicket.propTypes = {
    onClose: PropTypes.func.isRequired,
    firebase: PropTypes.object.isRequired,
    picture: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired
}

export default function DialogForPitTicket(props) {

    const onClose = props.onClose

    const [dialogTitle, setDialogTitle] = useState(undefined)
    const [pitTicketImageUrl, setPitTicketImageUrl] = useState(undefined)

    useEffect(async () => {
        setDialogTitle(`Pit Ticket ${props.number}`)
        const storage = getStorage();
        if (props.picture) {
          setPitTicketImageUrl(await getDownloadURL(ref(storage, `pittickets/${props.picture}`)))
        } else {
          setPitTicketImageUrl(undefined)
        }
    }, [props.picture, props.number] )

    if (!dialogTitle) return (<div></div>)

    return (
        <Dialog open={true} onClose={props.onClose} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle id="simple-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <Grid container justify="center" spacing={2} >
                <Grid item xs={4}>
                    { pitTicketImageUrl && <Image src={pitTicketImageUrl} /> }
                    { !pitTicketImageUrl && <TextField value={"No pit ticket picture available"} inputProps={{ readOnly: true }} fullWidth /> }
              </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose} >Close</Button>
            </DialogActions>
        </Dialog> 
    )

}