import { getFirestore, collection, query, onSnapshot, where, getDocs } from "firebase/firestore";
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import { Tabs, Tab, TextField, Button } from '@material-ui/core';
import _ from 'lodash'
import OrderUtilities from '../common/OrderUtilities';
import OrdersTable from '../orders/OrdersTable'
import { convertDateToFirestoreTimestamp,
  convertFirestoreTimestampToDate } from '../common/FirestoreUtilities'
import { DatePicker } from "@material-ui/pickers"
import DriverUtilities from '../common/DriverUtilities'

DriverLoads.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  customerRefs: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  pricing: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  rowColorMap: PropTypes.object.isRequired
}

const columnsForTable = [
  'ref',
  'dispatchSequence',
  'driver',
  'subdivision',
  'orderDate',
  'preloadedDate',
  'actualDeliveryDate',
  'notes',
  'priority',
  'deliveryAddress',
  'city',
  'loads',
  'heavy',
  'material',
  'purpose',
  'contact'
]



export default function DriverLoads(props) {

  const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate()

  const addMonths = (input, months) => {
    const date = new Date(input)
    date.setDate(1)
    date.setMonth(date.getMonth() + months)
    date.setDate(Math.min(input.getDate(), getDaysInMonth(date.getFullYear(), date.getMonth()+1)))
    return date
  }

  const addDays = (input, days) => {
    const date = new Date(input)
    date.setDate(date.getDate() + days)
    return date
  }

  const driverListRef = useRef(undefined)
  driverListRef.current = DriverUtilities.performFirstNameSortForDriverNames(
    DriverUtilities.limitDriversToActiveDrivers(props.drivers)
      .map(driver => {
        return driver.name.trim()
      })
  )

  const [dateRange, setDateRange] = useState({
    startDate: convertDateToFirestoreTimestamp(props.firebase, new Date()),
    endDate: convertDateToFirestoreTimestamp(props.firebase, addDays(new Date(), 1))
  })
  const orderUtilitiesRef = useRef(undefined)
  const dispatchedOrdersQueryRef = useRef(undefined)
  const preloadedOrdersQueryRef = useRef(undefined)
  const preloadOrderQueryRef = useRef(undefined)
  const firestoreRef = useRef(getFirestore(props.firebase.app()))
  const driverQueryRef = useRef(props.db.collection('drivers'))
  const [driversOrdersWithRefs, setDriversOrdersWithRefs] = useState(undefined)
  const [driversPreloadedOrdersWithRefsMap, setPreloadedDriversOrdersWithRefsMap] = useState(undefined)
  const [value, setValue] = useState(driverListRef.current[0])
  const [doubles, setDoubles] = useState(0)
  const [hours, setHours] = useState(0)
  const [bank, setBank] = useState(0)
  const [withdraw, setWithdraw] = useState(0)
  const [driversRef, setDriversRef] = useState(undefined)

  dispatchedOrdersQueryRef.current = query(collection(firestoreRef.current, 'orders')
    ,where('delivered', '==', true)
    ,where('actualDeliveryDate', '>=', dateRange.startDate)
    ,where('actualDeliveryDate', '<', dateRange.endDate)
    ,where('cancelled', '==', false)
    ,where('preloaded', '==', false)
  )

  preloadedOrdersQueryRef.current = query(collection(firestoreRef.current, 'orders')
    ,where('preloadedDate', '>=', dateRange.startDate)
    ,where('preloadedDate', '<', dateRange.endDate)
    ,where('preloaded', '==', true)
    ,where('cancelled', '==', false)
  )

  useEffect(() => {

    const areaMap = _.keyBy(props.cities, (o) => { return o.area.trim() })
    const citiesMap = _.keyBy(props.cities, (o) => { return o.name.trim() })
    const subdivisionsMap = _.keyBy(props.subdivisions, (o) => { return o.trim() })
    const materialMap = _.keyBy(props.materials, (o) => { return o.name.trim() })
    const purposeMap = _.keyBy(props.purposes, (o) => { return o.name.trim() })
  
    orderUtilitiesRef.current = new OrderUtilities(
      props.firebase, 
      props.db,
      areaMap,
      props.customerRefs,
      citiesMap,
      subdivisionsMap,
      materialMap,
      purposeMap,
      props.pricing
    )

    let driversUnsubscribe = driverQueryRef.current.onSnapshot(querySnapshot => {
      const tempDriversRef = querySnapshot.docs
      setDriversRef(tempDriversRef)
    })  
  
    let deliveredOrdersUnsubscribe = onSnapshot(dispatchedOrdersQueryRef.current, querySnapshot => {
      let tempOrdersWithRefs = querySnapshot.docs.map(orderRef => {
        return orderUtilitiesRef.current.convertOrderForOrderTable(orderRef)
      })  
      const driverOrdersMap = {}
      driverListRef.current.forEach(driver => {
        driverOrdersMap[driver.trim()] = []
      })
      tempOrdersWithRefs.forEach(orderWithRef => {
        if (driverOrdersMap[orderWithRef.driver.trim()]) {
          driverOrdersMap[orderWithRef.driver.trim()].push(orderWithRef)
        }
      }) 
      setDriversOrdersWithRefs(driverOrdersMap)
    })

    let preloadedOrdersUnsubscribe = onSnapshot(preloadedOrdersQueryRef.current, querySnapshot => {
      let tempOrdersWithRefs = querySnapshot.docs.map(orderRef => {
        return orderUtilitiesRef.current.convertOrderForOrderTable(orderRef)
      })  
      const driverPreloadedOrdersMap = {}
      driverListRef.current.forEach(driver => {
        driverPreloadedOrdersMap[driver.trim()] = []
      })
      tempOrdersWithRefs.forEach(orderWithRef => {
        if (driverPreloadedOrdersMap[orderWithRef.driver.trim()]) {
          driverPreloadedOrdersMap[orderWithRef.driver.trim()].push(orderWithRef)
        }
      }) 
      setPreloadedDriversOrdersWithRefsMap(driverPreloadedOrdersMap)
    })

    return () => {
      deliveredOrdersUnsubscribe()
      preloadedOrdersUnsubscribe()
      driversUnsubscribe()
    }
  }, [props.firebase,
    props.db,
    props.query,
    props.customerRefs, 
    props.materials, 
    props.purposes, 
    props.cities, 
    props.pricing,
    props.actions,
    props.drivers,
    dateRange]
  )

  useEffect(() => {
    setValuesForDriver(value, addDays(convertFirestoreTimestampToDate(props.firebase, dateRange.endDate),-1))
  },[value])
  
  const findDriverForValue = (value) => {
    if (!driversRef) {
      return props.drivers.filter(driver => {
        return driver.name === value
      })[0]
    }
    return driversRef.filter(driverRef => {
      return driverRef.data().name === value
    })[0].data()
}

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleDayViewDateChange = (selectedDate) => {
    setValuesForDriver(value, selectedDate)
    const newDateRange = {
      startDate: convertDateToFirestoreTimestamp(props.firebase, selectedDate),
      endDate: convertDateToFirestoreTimestamp(props.firebase, addDays(selectedDate, 1))
    }
    setDateRange(newDateRange)
  }

  const setValuesForDriver = (driverName, selectedDate) => {
    const driver = findDriverForValue(driverName)
    if (!driver) {
      setDoubles(0)
      setHours(0)
      setBank(0)
      setWithdraw(0)
      return
    }
    const dateAsTimestamp = convertDateToFirestoreTimestamp(props.firebase, selectedDate)
    let orderSummary = undefined
    if (driver.dailySummaries) {
      orderSummary = driver.dailySummaries[dateAsTimestamp]
    }
    if (!orderSummary) {
      setDoubles(0)
      setHours(0)
      setBank(0)
      setWithdraw(0)
      return
    }
    setDoubles(orderSummary.doubles ? parseInt(orderSummary.doubles) : 0)
    setHours(orderSummary.hours ? parseFloat(orderSummary.hours) : 0)
    setBank(orderSummary.bank ? parseInt(orderSummary.bank) : 0)
    setWithdraw(orderSummary.withdraw ? parseInt(orderSummary.withdraw) : 0)
  }

  const dateSelectionView = () => {
    return (
      <DatePicker
        label="Day to View"
        value={addDays(convertFirestoreTimestampToDate(props.firebase, dateRange.endDate),-1)}
        onChange={handleDayViewDateChange}
        animateYearScrolling
      />
    )
  }

  const getSalesForDate = (driver) => {
    if ((driversOrdersWithRefs[driver].length < 1) &&
        (driversPreloadedOrdersWithRefsMap[driver].length < 1))
     {
      return 0
    }
    const sales = driversOrdersWithRefs[driver].reduce(
      (accumulator, currentValue) => { return accumulator + parseInt(currentValue.price) || 0},
      0)
    const preloadedSales = driversPreloadedOrdersWithRefsMap[driver].reduce(
      (accumulator, currentValue) => { return accumulator + parseInt(currentValue.price) || 0},
      0)
    return sales + preloadedSales
  }

  const onSave = () => {
    DriverUtilities.recordDaySummaryForDriver(findDriverForValue(value), dateRange.startDate, doubles, hours, bank, withdraw, driversRef)
  } 

  if (!driversOrdersWithRefs || !driversPreloadedOrdersWithRefsMap) {
    return <div></div>
  }

  return (
    <div>
      <div style={{ float:"left", width:"15%" }}>
        <Tabs value={value} 
          onChange={handleTabChange}
          orientation="vertical"
          variant="scrollable" >
        {driverListRef.current.map(driver => (
          <Tab key={driver} label={`${driver.split(' ')[0]} (${driversOrdersWithRefs[driver].length + driversPreloadedOrdersWithRefsMap[driver].length})`} value={driver}/>
        ))}
        </Tabs>
      </div>
      <div style={{ float:"left", width:"85%" }}>
        <div>
          <span>{dateSelectionView()}</span>
          <span>
            <TextField id="outlined-basic" label="Doubles" variant="outlined" value={doubles} onChange={(event) => setDoubles(event.target.value)}/>
            <TextField id="outlined-basic" label="Hours" variant="outlined" value={hours} onChange={(event) => setHours(event.target.value)}/>
            <TextField id="outlined-basic" label="Bank" variant="outlined" value={bank} onChange={(event) => setBank(event.target.value)}/>
            <TextField id="outlined-basic" label="Withdraw" variant="outlined" value={withdraw} onChange={(event) => setWithdraw(event.target.value)}/>
            <Button variant="contained" onClick={onSave}>Save</Button>
            <h2>{`Sales - ${getSalesForDate(value)}`}</h2>
          </span>
        </div>
        <div>
          <OrdersTable firebase={props.firebase}
            db={props.db}
            title={`Delivered Orders - ${value}`}
            columns={columnsForTable}
            customerRefs={props.customerRefs}
            actions={['ready']}
            materials={props.materials}
            purposes={props.purposes}
            cities={props.cities}
            subdivisions={props.subdivisions}
            pricing={props.pricing}
            drivers={props.drivers}
            rowColorMap={props.rowColorMap}
            ordersWithRefs={driversOrdersWithRefs[value]}
            orderUtilities={orderUtilitiesRef.current}
            tabValue={value}
          />
        </div>
        <div>
          <OrdersTable firebase={props.firebase}
            db={props.db}
            title={`Preloaded Orders - ${value}`}
            columns={columnsForTable}
            customerRefs={props.customerRefs}
            actions={['ready']}
            materials={props.materials}
            purposes={props.purposes}
            cities={props.cities}
            subdivisions={props.subdivisions}
            pricing={props.pricing}
            drivers={props.drivers}
            rowColorMap={props.rowColorMap}
            ordersWithRefs={driversPreloadedOrdersWithRefsMap[value]}
            orderUtilities={orderUtilitiesRef.current}
            tabValue={value}
          />
        </div>
      </div>
    </div>
  ) 
}