import { customerMustHaveName } from './errorMessages';

export default class CustomerUtilities {

  constructor(firebase, db, customerRefs) {
    this.firebase = firebase
    this.db = db
    this.customerRefs = customerRefs;
  }

  getCustomerTemplate() {
    return {
      name: null, 
      pricingModel: 'default', 
      requiresPO: false,
      poPrefix: '',
      subdivision: '',
      defaultNotes: '',
      defaultContact: '',
      defaultEmailPhone: ''
    }
  }

  addCustomer = async (customer) => {
    let newCustomer = {...this.getCustomerTemplate(), ...customer}
    // customer must have a name
    if (!newCustomer.name) {
      throw new Error(customerMustHaveName)
    }
    let customerRef = this.db.collection('customers').doc();
    await customerRef.set(newCustomer);
  }

  updateCustomer = async (id, data) => {
    let newCustomer = {...this.getCustomerTemplate(), ...data}
    // customer must have a name
    if (!newCustomer.name) {
      throw new Error(customerMustHaveName)
    }
    await this.db.collection('customers').doc(id).set(newCustomer);
  }

  deleteCustomer = async (id) => {
    await this.db.collection('customers').doc(id).delete();
  }

  getByName = (name) => {
    const customersWithName = this.customerRefs.filter(customerRef => {
      return customerRef.data().name === name
    })
    return customersWithName.length > 0 ? customersWithName[0] : undefined
  }

}