import React from 'react'
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import OrderReferenceOrders from './OrderReferenceOrders'

DialogForOrderReferenceOrders.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  customerRefs: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  pricing: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  rowColorMap: PropTypes.object.isRequired,
  orderReference: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default function DialogForOrderReferenceOrders(props) {

  const onClose = props.onClose

  return (
    <Dialog fullScreen open={true} onClose={props.onClose} fullWidth={true} disableBackdropClick={true} >
      <DialogTitle id="simple-dialog-title">Orders For Order Reference {props.orderReference}</DialogTitle>
      <DialogContent>
        <OrderReferenceOrders 
          firebase={props.firebase} 
          db={props.db} 
          customerRefs={props.customerRefs} 
          materials={props.materials}
          purposes={props.purposes}
          cities={props.cities}
          pricing={props.pricing}
          drivers={props.drivers}
          rowColorMap={props.rowColorMap}
          orderReference={props.orderReference}
          customer={props.customer}
        /> 
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
