export default class OrderUtilities {

  static performDefaultSortForUsers = (userRefs) => {

    return userRefs.sort((subRefA, subRefB) => {
      return subRefA.data().name < subRefB.data().name ? -1 : 1
    })  

  }

  static isAdmin = async (db, email) => {

    const usersSnapshot = await db.collection("users").where("email", "==", email).get()
    const filteredByDriver = usersSnapshot.docs.filter((doc) => {
      // doc.data() is never undefined for query doc snapshots
      return doc.data().roles.includes('admin')
    })
    return (filteredByDriver.length > 0) 

  }

  static isDriver = async (db, email) => {

    const usersSnapshot = await db.collection("users").where("email", "==", email).get()
    const filteredByDriver = usersSnapshot.docs.filter((doc) => {
      // doc.data() is never undefined for query doc snapshots
      return doc.data().roles.includes('driver')
    })
    return (filteredByDriver.length > 0) 
  }

  static add = async (db, newUser) => {

    await db.collection("users").add(newUser)

  }

  static update = async (db, originalEmail, newUser) => {

    const originalUserRef = await OrderUtilities.getByEmail(db, originalEmail)
    await originalUserRef.ref.update(newUser)
    return await OrderUtilities.getByEmail(db, newUser.email)

  }

  static getByEmail = async (db, email) => {

    const usersSnapshot = await db.collection("users").where("email", "==", email).get()
    if (usersSnapshot.docs.length > 0) {
      return usersSnapshot.docs[0]
    }
    return null

  }

  static convertAllForTable = (docs) => {
    const result = docs.map(userRef => {
      return { ref: userRef.id, ...userRef.data() }
    })
    return result
  }


}