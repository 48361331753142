import React, { useState } from 'react'
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { convertFirestoreTimestampToDateString,
        convertFirestoreTimestampToDate} from '../common/FirestoreUtilities'

export default function DispatchSequence(props) {

  const [orders, setOrders] = useState([...props.orders])

  const determineIfOldOrder = (order) => {
    if (!order.orderDate) return false
    const millisecondsInDay = 86400000
    const orderDate = convertFirestoreTimestampToDate(props.firestore, order.orderDate)
    const orderDateTime = orderDate.getTime()
    const todayDate = new Date()
    const todayTime = todayDate.getTime()
    return (todayTime - orderDateTime) > (3 * 7 * millisecondsInDay)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
        return
    }

    console.log(`dragEnd ${result.source.index} to  ${result.destination.index}`)
    const tempOrders = reorder(
        orders,
        result.source.index,
        result.destination.index
    )

    props.onSequenceChange(tempOrders)
    setOrders(tempOrders)

  }

  return (
    <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Order Date</TableCell>
                    <TableCell >Loads</TableCell>
                    <TableCell >Material</TableCell>
                    <TableCell >Purpose</TableCell>
                    <TableCell >Contact</TableCell>
                    <TableCell >PO</TableCell>
                    <TableCell >City</TableCell>
                    <TableCell >Address</TableCell>
                    <TableCell >Delivery Date</TableCell>
                    <TableCell >Subdivision</TableCell>
                    <TableCell >Notes</TableCell>
                </TableRow>
            </TableHead>
            <TableBody component={DroppableComponent(onDragEnd)}>
                {orders.map((order, index) => {
                    const oldOrder = determineIfOldOrder(order)
                    let borderColor = 'rgb(255,255,255)'
                    borderColor = oldOrder ? 'rgb(0,255,0)' : borderColor
                    // priority overrides oldOrder
                    borderColor = order.priority ? 'rgb(0,0,255)' : borderColor
                    const borderStyle = order.priority || oldOrder ? 'solid' : 'none'
                    const rowStyle = {
                        backgroundColor: props.rowColorMap.get(order.purpose.toUpperCase()),
                        borderColor: borderColor,
                        borderStyle: borderStyle
                    }
                    const cellStyle = {
                        color: order.heavy? 'rgb(255,0,0)' : 'rgb(0,0,0)'
                    }
                    return (
                        <TableRow component={DraggableComponent(order.ref, index, rowStyle)} key={index} >
                            <TableCell style={cellStyle}>{convertFirestoreTimestampToDateString(props.firebase, order.orderDate)}</TableCell>
                            <TableCell style={cellStyle}>{order.loads}</TableCell>
                            <TableCell style={cellStyle} >{order.material}</TableCell>
                            <TableCell style={cellStyle} >{order.purpose}</TableCell>
                            <TableCell style={cellStyle} >{order.contact}</TableCell>
                            <TableCell style={cellStyle} >{order.po}</TableCell>
                            <TableCell style={cellStyle} >{order.city}</TableCell>
                            <TableCell style={cellStyle} >{order.billToAddress}</TableCell>
                            <TableCell style={cellStyle} >{convertFirestoreTimestampToDateString(props.firebase, order.anticipatedDeliveryDate)}</TableCell>
                            <TableCell style={cellStyle} >{order.subdivision}</TableCell>
                            <TableCell style={cellStyle} >{order.notes}</TableCell>
                        </TableRow>
                    )
                  }
                )}
            </TableBody>
        </Table>
    </TableContainer>
  )
}

const DraggableComponent = (id, index, style) => (props) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
      // styles we need to apply on draggables
      ...draggableStyle,
      ...style,
      ...(isDragging && {
          background: "rgb(235,235,235)"
      })
  })

  return (
      <Draggable draggableId={id} index={index}>
          {(provided, snapshot) => (
              <TableRow
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

                  {...props}
              >
                  {props.children}
              </TableRow>
          )}
      </Draggable>
  )
}

const DroppableComponent = (
  onDragEnd: (result, provided) => void) => (props) =>
{
  return (
      <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'1'} direction="vertical">
              {(provided) => {
                  return (
                      <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                          {props.children}
                          {provided.placeholder}
                      </TableBody>
                  )
              }}
          </Droppable>
      </DragDropContext>
  )
}