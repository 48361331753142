import React, {forwardRef, useEffect, useRef, useState } from 'react'
import MaterialTable from 'material-table'
import { Button } from '@material-ui/core'
import DialogForPitTicket from './DIalogForPitTicket'
import { convertFirestoreTimestampToDate } from "../common/FirestoreUtilities"

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function PitTIcketsTable(props) {

  const pitTicketsQueryRef = useRef(undefined)
  const [pitTicketsWithRefs, setPitTicketsWithRefs] = useState(undefined)
  const [showPitTicket, setShowPitTicket] = useState(undefined)

  useEffect(() => {
    pitTicketsQueryRef.current = props.db.collection('pit_tickets')
        .where("pitName", "==", props.pit)

    let pitTicketsUnsubscribe = pitTicketsQueryRef.current
      .onSnapshot(querySnapshot => {
        const tempPitTicketsWithRefs = querySnapshot.docs.map(pitTicketRef => {
            const data = pitTicketRef.data()
            data.date = convertFirestoreTimestampToDate(props.firebase, data.date)
            return {ref: pitTicketRef.id, ...data}
        })
        setPitTicketsWithRefs(tempPitTicketsWithRefs)
  })
    return () => {
        pitTicketsUnsubscribe()
    }
  }, [props.firebase,
    props.db,
    props.pit]
  )

  const closePitTicket = () => {
    setShowPitTicket(undefined)
  }


  if (!pitTicketsWithRefs) {
    return <div></div>
  }

  if (showPitTicket) {
    return <DialogForPitTicket picture={showPitTicket.picture} number={showPitTicket.number} onClose={closePitTicket}></DialogForPitTicket>
  }

  return (
    <MaterialTable
      icons={tableIcons}
      title={"Pit Tickets"}
      columns={[
        { field: 'ref', hidden: true },
        { 
            title: 'Date', 
            field: 'date',
            type: 'date',
            customFilterAndSearch: (term, rowData) => {
                return this.testDateInCustomFilterAndSearch(term, rowData.date)
            } 
        },
        { 
            title: 'Number', 
            field: 'number',
            render: rowData => {
              return <Button variant="contained" color="primary" onClick={() => setShowPitTicket(rowData)}>{rowData.number}</Button>
            }
        }, 
        { title: "Truck", field: 'truck'},
    ]}
      data={pitTicketsWithRefs}  
      options={{
        filtering: true,
        search: true,
        sorting: true,
        paging: false,
        addRowPosition: 'first'
      }}
    />
  )

}