import React, { useState, useEffect, useRef } from 'react'
import OrderUtilities from '../common/OrderUtilities'
import PricingModelUtilities from '../common/PricingModelUtilities'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

UploadNewSubdivision.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  customerRefs: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired, 
  pricing: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
}

export default function UploadNewSubdivision(props) {

  const newLog = ['Waiting to process...']
  const [customerRef, setCustomerRef] = useState(null)
  const [filteredCustomerRefs, setFilteredCustomerRefs] = useState(null)
  const [city, setCity] = useState(null)
  const [subdivision, setSubdivision] = useState(null)
  const [plumbingLoads, setPlumbingLoads] = useState(null)
  const [brickLoads, setBrickLoads] = useState(null)
  const [finalGradeLoads, setFinalGradeLoads] = useState(null)

  const logRef = useRef(newLog)

  const [selectedFile, setSelectedFile] = useState(undefined)

  const errorsRef = useRef(undefined)
  const orderUtilitiesRef = useRef(undefined)

  const propertiesInInputFile = [
    'streetNumber',
    'streetName'
  ]

  const columnMap = propertiesInInputFile.map((property,index) => {
    return {columnIndex: index, property: property}
  })

  const pricingModelUtilities = new PricingModelUtilities(props.pricing)

  useEffect(() => {

    const areaMap = _.keyBy(props.cities, (o) => { return o.name.trim() })
    const citiesMap = _.keyBy(props.cities, (o) => { return o.name.trim() })
    const subdivisionsMap = _.keyBy(props.subdivisions, (o) => { return o.trim() })
    const materialMap = _.keyBy(props.materials, (o) => { return o.name.trim() })
    const purposeMap = _.keyBy(props.purposes, (o) => { return o.name.trim() })

    setFilteredCustomerRefs(props.customerRefs.filter(customerRef => {
      return !customerRef.data().subdivision ? false : true
    }))

    orderUtilitiesRef.current = new OrderUtilities(
      props.firebase, 
      props.db,
      areaMap,
      props.customerRefs,
      citiesMap,
      subdivisionsMap,
      materialMap,
      purposeMap,
      props.pricing
    )

  }, [
    props.firebase,
    props.db,
    props.pricing,
    props.cities,
    props.customerRefs,
    props.materials,
    props.purposes,
    props.subdivisions
  ])

  const onFileChange = (event) => {
    logRef.current = newLog
    addLogEntry(`Selected file - '${event.target.files[0].name}'`)
    setSelectedFile(event.target.files[0])
  }

  const addLogEntry = (entry) => {
    logRef.current.push(entry)
  }

  const addErrorLogEntry = (entry) => {
    errorsRef.current = true
    addLogEntry(`ERROR - ${entry}`)
  }

  const convertLineToObject = (line => {
    const lineAsArray = line.trim().split('\t')
    if (line.trim() === '') return undefined
    if (lineAsArray.length !== 2) {
      addErrorLogEntry(`Invalid line '${line}'`)
      return undefined
    }
    const result = {}
    columnMap.forEach(column => {
      result[column.property] = lineAsArray[column.columnIndex].trim().replace(/"/g, '')
    })
    return result
  })

  const processFile = async (fileContents) => {
    const lines = fileContents.split('\n')
    const lots = []
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i]
      if (!line) continue
      const lot = convertLineToObject(line)
      if (!lot) continue
      lots.push(convertLineToObject(line))
    }
    if (errorsRef.current) {
      alert('Review errors and try again')
      setSelectedFile(undefined)
      return 
    } 
    await submitOrders(lots)
  }

  const readFile = () => {
    const fr = new FileReader()
    fr.onload= async () => { 
      await processFile(fr.result)
      if (errorsRef.current) return
      props.onClose()
    }
    fr.readAsText(selectedFile, 'UTF-8')
  }

  const handleCustomerChange = (event, value) => {
    if (customerRef !== value) {
      setCustomerRef(value)
      if (!value) return
      setSubdivision(value.data().subdivision)
    }
  }

  const handleCityChange = (event, value) => {
    if (city !== value) {
      setCity(value)
    }
  }

  const handlePlumbingLoadsChange = (event, value) => {
    if (plumbingLoads !== event.target.value) {
      setPlumbingLoads(event.target.value)
    }
  }

  const handleBrickLoadsChange = (event, value) => {
    if (brickLoads !== event.target.value) {
      setBrickLoads(event.target.value)
    }
  }

  const handleFinalGradeLoadsChange = (event, value) => {
    if (finalGradeLoads !== event.target.value) {
      setFinalGradeLoads(event.target.value)
    }
  }

  const getPrice = (material, loads) => {
    return pricingModelUtilities.getPrice(loads, customerRef.data().pricingModel, material, new Date(), city)
  }


  const submitOrders = async (lines) => {
    if (!customerRef) {
      alert("Customer is required")
      return
    }
    if (!city) {
      alert("City is required")
      return
    }
    const orderLines = []
    lines.forEach(line => {
      const newBaseLine = {}
      newBaseLine.customer = customerRef.data().name
      newBaseLine.billToAddress = `${line.streetNumber.trim()} ${line.streetName.trim()}`
      newBaseLine.subdivision = subdivision
      newBaseLine.city = city.name
      const newLine = {...newBaseLine}

      if (plumbingLoads > 0) {
        newLine.loads = plumbingLoads
        newLine.material = 'c/s'
        newLine.purpose = 'plumb'
        newLine.price = getPrice(newLine.material, newLine.loads)
        orderLines.push({...newLine})
      }

      if (brickLoads > 0) {
        newLine.loads = brickLoads
        newLine.material = 'm/s'
        newLine.purpose = 'brick'
        newLine.price = getPrice(newLine.material, newLine.loads)
        orderLines.push({...newLine})
      }

      if (finalGradeLoads > 0) {
        newLine.loads = finalGradeLoads
        newLine.material = 'c/s'
        newLine.purpose = 'f/g'
        newLine.price = getPrice(newLine.material, newLine.loads)
        orderLines.push({...newLine})
      }

    })
    const newOrders = []
    for (let i = 0; i < orderLines.length; i++) {
      const newOrder = orderUtilitiesRef.current.getEmptyOrder()
      newOrder.customer = orderLines[i].customer
      newOrder.billToAddress = orderLines[i].billToAddress
      newOrder.deliveryAddress = orderLines[i].billToAddress
      newOrder.subdivision = orderLines[i].subdivision
      newOrder.city = orderLines[i].city
      newOrder.material = orderLines[i].material
      newOrder.purpose = orderLines[i].purpose
      newOrder.loads = orderLines[i].loads
      newOrder.price = orderLines[i].price
      newOrders.push(newOrder)
    }
    const poNumber = customerRef.data().requiresPO ? 'NPO' : ''
    await orderUtilitiesRef.current.addMultiLineOrder(customerRef.data().name, poNumber, newOrders, true)
      .then(() => {
        props.onClose()
      })
      .catch( error => {
        if (error) {
          alert(error.message)
        }
      })
  }

  if (!filteredCustomerRefs) {
    return (<div></div>)
  }

  return (
    <div> 
      <ol>
        <li>Prepare file from spreadsheet</li>
        <ol>
          <li>Remove all rows except job rows (including column headings)</li>
          <li>Remove all columns except for address and street</li>
          <li>Save results as tab delimited text</li>
          <li>If there are more than 150 jobs the file must be split</li>
        </ol>
        <li>Select customer (only customers with assigned subdivisions are allowed) </li>
        <li>Enter the city where the subdivision is located</li>
        <li>Choose the text file to upload below</li>
        <li>Click upload</li>
      </ol>
      <Autocomplete
        id="customers"
        size="small"
        fullWidth={true}
        required={true}
        options={filteredCustomerRefs.sort((customerRefA, customerRefB) => {
          return customerRefA.data().name < customerRefB.data().name ? -1 : 1
        })}
        autoSelect={true}
        autoComplete={true}
        autoHighlight={true}
        openOnFocus={true}
        selectOnFocus={true}      
        getOptionLabel={(option) => option.data().name}
        onChange={handleCustomerChange}
        getOptionSelected={(option, value) => option.data().name === value.data().name }
        renderInput={(params) => <TextField {...params} label="Customer Name" InputLabelProps={{ shrink: true }} />}
      />
      <Autocomplete
        id="city"
        size="small"
        fullWidth={true}
        required={true}
        options={props.cities.sort((cityA, cityB) => {
          return cityA.name < cityB.name ? -1 : 1
        })}
        autoSelect={true}
        autoComplete={true}
        autoHighlight={true}
        openOnFocus={true}
        selectOnFocus={true}      
        getOptionLabel={(option) => option.name}
        onChange={handleCityChange}
        getOptionSelected={(option, value) => option.name === value.name }
        renderInput={(params) => <TextField {...params} label="City" InputLabelProps={{ shrink: true }} />}
      />
      <TextField
        id="plumbingLoads"
        size="small"
        label="Plumbing Loads"
        InputLabelProps={{ shrink: true }}
        onChange={handlePlumbingLoadsChange}
        fullWidth={true}
        value={plumbingLoads}
      />
      <TextField
        id="brickLoads"
        size="small"
        label="Brick Loads"
        InputLabelProps={{ shrink: true }}
        onChange={handleBrickLoadsChange}
        fullWidth={true}
        value={brickLoads}
      />
      <TextField
        id="finalGradeLoads"
        size="small"
        label="Final Grade Loads"
        InputLabelProps={{ shrink: true }}
        onChange={handleFinalGradeLoadsChange}
        fullWidth={true}
        value={finalGradeLoads}
      />
      <div> 
          <input type="file" onChange={onFileChange} disabled={customerRef && city && plumbingLoads && brickLoads && finalGradeLoads ? false : true}/> 
          <button onClick={readFile} disabled={selectedFile ? false : true}> 
            Upload! 
          </button> 
      </div> 
      <div> 
        <button onClick={props.onClose}> 
          Cancel
        </button> 
      </div> 
      <div>
        <Typography>
          {logRef.current.map(logLine => (
            <p>{logLine}</p>
          ))}
        </Typography>
      </div>
    </div> 
  )
}