import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Tabs, Tab } from '@material-ui/core'
import PitTicketsTable from "./PitTicketsTable"
import { convertFirestoreTimestampToDate } from "../common/FirestoreUtilities"

DialogForPitTickets.propTypes = {
    onClose: PropTypes.func.isRequired,
    db: PropTypes.object.isRequired,
    firebase: PropTypes.object.isRequired,
    pit: PropTypes.string.isRequired
}

export default function DialogForPitTickets(props) {

    const onClose = props.onClose

    const [dialogTitle, setDialogTitle] = useState(undefined)

    useEffect(() => {
        setDialogTitle(`Pit Tickets ${props.pit}`)
    }, [props.pit])

    if (!dialogTitle) return (<div></div>)

    return (
        <Dialog open={true} onClose={props.onClose} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle id="simple-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={12}>
                        <PitTicketsTable pit={props.pit} db={props.db} firebase={props.firebase} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose} >Close</Button>
            </DialogActions>
        </Dialog> 
    )

}