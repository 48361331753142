import React, {useState} from 'react'
import PropTypes from 'prop-types';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers';

ConvertOrderToReady.propTypes = {
  row: PropTypes.object.isRequired,
  orderUtilities: PropTypes.object.isRequired,
  customerRefs: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default function ConvertOrderToReady(props) {

  const onClose = props.onClose

  const customerMap = props.customerRefs.map(customerRef => {
    return customerRef.data()
  }).reduce((obj, item) => {
    obj[item.name] = item
    return obj
  }, {})
  const customer = customerMap[props.row.customer]


  // const tempDate = props.row.requestedDeliveryDate 
  //   ? props.row.requestedDeliveryDate
  //   : null
  const [po, setPO] = useState(customer.poPrefix ? customer.poPrefix : props.row.po)
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState(null)
  const [loads, setLoads] = useState(props.row.loads)
  const [contact, setContact] = useState(customer.defaultContact ? customer.defaultContact : props.row.contact)
  const [contactEmail, setContactEmail] = useState(customer.defaultEmailPhone ? customer.defaultEmailPhone : props.row.contactEmail)

  const close = () => {
    onClose()
  }

  const onCancel = () => {
    close()
  }

  const onConvertToReady = async () => {
    const data = { ...props.row }
    delete data.ref
    delete data.tableData
    if (data.dispatched) {
      await props.orderUtilities.setDispatchedOrderToReady(props.row.ref, data)     
    }
    await props.orderUtilities.setFutureOrderToReady(props.row.ref, data, requestedDeliveryDate, po, contact, contactEmail, loads)
    close()
  }

  const handlePOChange = (event) => {
    setPO(event.target.value)
  }

  const handleLoadsChange = (event) => {
    setLoads(event.target.value)
  }

  const handleContactChange = (event) => {
    setContact(event.target.value)
  }

  const handleContactEmailChange = (event) => {
    setContactEmail(event.target.value)
  }

  const handleRequestedDeliveryDateChange = (value) => {
    setRequestedDeliveryDate(value)
  }

  return (
    <Dialog open={true} onClose={close} PaperProps={{
      style: {
        backgroundColor: props.materialColor,
      },
    }}>
      <DialogTitle id="simple-dialog-title">Get Ready to Dispatch</DialogTitle>
      <DialogContent>
        <TextField
          id="loads"
          size="small"
          label="Loads"
          variant="outlined"
          defaultValue={loads}
          onChange={handleLoadsChange} 
          fullWidth
        />
        <TextField
          id="po"
          size="small"
          label="PO"
          variant="outlined"
          defaultValue={po}
          onChange={handlePOChange} 
          fullWidth
        />
        <KeyboardDatePicker 
          value={requestedDeliveryDate} 
          onChange={handleRequestedDeliveryDateChange} 
          format="MM/DD/YYYY"
          label="Requested Delivery Date"
        />
        <TextField
          id="contact"
          size="small"
          label="Contact"
          variant="outlined"
          defaultValue={contact}
          onChange={handleContactChange} 
          fullWidth
        />
        <TextField
          id="contactEmail"
          size="small"
          label="Contact Email/Phone Number"
          variant="outlined"
          defaultValue={contactEmail}
          onChange={handleContactEmailChange} 
          fullWidth
        />
      </DialogContent>
      <DialogActions>
          <Button color="primary" onClick={onCancel}>Cancel</Button>
          <Button color="primary" onClick={onConvertToReady}>Convert To Ready</Button>
      </DialogActions>
    </Dialog>
  )
}
