import { CitiesUtilitiesError } from './errorMessages'

export const performDefaultSortForCitiesRefs = (cityRefs) => {
  return cityRefs.sort((cityRefA, cityRefB) => {
    return cityRefA.data().name < cityRefB.data().name ? -1 : 1
  })  
}

export default class CitiesUtilities {

  constructor(firebase, db, citiesRefs) {
    this.firebase = firebase
    this.db = db
    this.citiesRefs = citiesRefs
  }

  fetchRefsForAll = () => {
    return this.citiesRefs
  }

  fetchAllForTable = () => {
    const result= this.citiesRefs.map(cityRef => {
      return { ref: cityRef.id, ...cityRef.data() }
    })
    return result
  }

  add = async (data) => {
    if (!data.area) throw new Error(CitiesUtilitiesError.areaIsRequired)
    await this.db.collection('cities').add(data)
  }

}