import moment from 'moment'
import { FirestoreUtilitiesError } from './errorMessages';

function convertFirestoreTimestampToDate(firebase, timestamp) {
    if (!timestamp) {
        return ``
    }
    let isJSDate = (timestamp instanceof Date)
    
    if (isJSDate) {
        // it's a javascript date
        return timestamp
    } else if (!((typeof timestamp.seconds === 'undefined') && (typeof timestamp.nanoseconds === 'undefined'))) {
        // it's the JSON representation of a timestamp
        const tempTimestamp = new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds)
        return tempTimestamp.toDate()
    } else {
        // must be a timestamp
        let result = ''
        try {
            result = timestamp.toDate()
            console.log(result)
        } catch (error) {
            console.log(`Bad timsestamp - ${timestamp}`)
        }
        return result
    }
}

function convertDateToFirestoreTimestamp(firebase, date) {
    if (!date) {
        return null
    }
    let tempDate = date
    if (moment.isMoment(tempDate)) {
        tempDate = tempDate.toDate()
    }
    if (!(tempDate instanceof Date)) {
        throw new Error(FirestoreUtilitiesError.objectPassedIsNotADate)
    }
    let dateWithoutTime = new Date(`${tempDate.getMonth()+1}/${tempDate.getDate()}/${tempDate.getFullYear()}`)
    return firebase.firestore.Timestamp.fromDate(dateWithoutTime)
}

function convertDateWithTimeToFirestoreTimestamp(firebase, date) {
    if (!date) {
        return null
    }
    let tempDate = date
    if (moment.isMoment(tempDate)) {
        tempDate = tempDate.toDate()
    }
    if (!(tempDate instanceof Date)) {
        throw new Error(FirestoreUtilitiesError.objectPassedIsNotADate)
    }
    return firebase.firestore.Timestamp.fromDate(date)
}

function convertFirestoreTimestampToDateString(firebase, timestamp) {
    if (!timestamp) {
        return ``
    }
    let isJSDate = (timestamp instanceof Date)
    let date = undefined
    
    if (isJSDate) {
        // it's a javascript date
        date = timestamp
    } else if (!((typeof timestamp.seconds === 'undefined') && (typeof timestamp.nanoseconds === 'undefined'))) {
        // it's the JSON representation of a timestamp
        const tempTimestamp = new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds)
        date = tempTimestamp.toDate()
    } else {
        // must be a timestamp
        date = timestamp.toDate()
    }
    let month = (date.getMonth()+1+'').padStart(2, '0')
    let day = (date.getDate()+'').padStart(2,'0')
    let year = date.getFullYear()+''
    return `${month}/${day}/${year}`
}

function convertFirestoreTimestampToDateTimeString(firebase, timestamp) {
    if (!timestamp) {
        return ``
    }
    let isJSDate = (timestamp instanceof Date)
    let date = undefined
    
    if (isJSDate) {
        // it's a javascript date
        date = timestamp
    } else if (!((typeof timestamp.seconds === 'undefined') && (typeof timestamp.nanoseconds === 'undefined'))) {
        // it's the JSON representation of a timestamp
        const tempTimestamp = new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds)
        date = tempTimestamp.toDate()
    } else {
        // must be a timestamp
        date = timestamp.toDate()
    }
    return date.toLocaleDateTimeString()
    // let month = (date.getMonth()+1+'').padStart(2, '0')
    // let day = (date.getDate()+'').padStart(2,'0')
    // let year = date.getFullYear()+''
    // return `${month}/${day}/${year}`
}

function convertDateToDateString(firebase, timestamp) {
    if (!timestamp) {
        return ``
    }
    let isJSDate = (timestamp instanceof Date)
    let date = undefined
    
    if (isJSDate) {
        // it's a javascript date
        date = timestamp
    } else if (!((typeof timestamp.seconds === 'undefined') && (typeof timestamp.nanoseconds === 'undefined'))) {
        // it's the JSON representation of a timestamp
        const tempTimestamp = new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds)
        date = tempTimestamp.toDate()
    } else {
        // must be a timestamp
        date = timestamp.toDate()
    }
    let month = (date.getMonth()+1+'').padStart(2, '0')
    let day = (date.getDate()+'').padStart(2,'0')
    let year = date.getFullYear()+''
    return `${month}/${day}/${year}`
}

function convertDateStringToFirestoreTimestamp(firebase, dateString) {
    if (!dateString) {
        return null
    }
    if (!(moment(dateString, moment.ISO_8601).isValid())) {
        throw new Error(FirestoreUtilitiesError.iso8601DateStringRequired)
    }
    let momentDate = moment(dateString, moment.ISO_8601)
    return firebase.firestore.Timestamp.fromDate(momentDate.toDate())
}

function convertMapToFirestoreTimestamp(firebase, map) {
    if (!map) {
        return null
    }
    if (typeof map.seconds === 'undefined') {
        return null
    }
    if (typeof map.nanoseconds === 'undefined') {
        return null
    }
    return new firebase.firestore.Timestamp(map.seconds, map.nanoseconds)
}

export { convertFirestoreTimestampToDate, 
    convertDateToFirestoreTimestamp, 
    convertDateWithTimeToFirestoreTimestamp,
    convertFirestoreTimestampToDateString,
    convertFirestoreTimestampToDateTimeString,
    convertDateStringToFirestoreTimestamp,
    convertMapToFirestoreTimestamp,
    convertDateToDateString
 }


