import React, {useState, forwardRef, useRef, useEffect} from 'react'
import MaterialTable from 'material-table'
import PropTypes from 'prop-types';
import PricingModelUtilities from '../common/PricingModelUtilities'; 
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import PossibleDuplicatesDialog from './PossibleDuplicatesDialog'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ThumbUp from '@material-ui/icons/ThumbUp';

NewOrderTable.propTypes = {
  customerRef: PropTypes.object,
  city: PropTypes.object,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  pricing: PropTypes.array.isRequired,
  rowColorMap: PropTypes.object.isRequired,
  lineItemsChanged: PropTypes.func.isRequired,
  lineItems: PropTypes.array.isRequired
}

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ThumbUp: forwardRef((props, ref) => <ThumbUp {...props} ref={ref} />)
};

export default function NewOrderTable(props) {

  const customerRef = useRef(undefined)
  const cityRef = useRef(undefined)
  const purposes = useRef(undefined)
  const materials = useRef(undefined)
  const newRowData = useRef(undefined)
  const possibleDups = useRef(undefined)

  const [openDup, setOpenDup] = useState(false)

  useEffect(() => {
    customerRef.current = props.customerRef
    cityRef.current = props.city

    purposes.current = props.purposes.concat([{name:''}])
    purposes.current = purposes.current.sort((purposeA, purposeB) => {
      return purposeA.name < purposeB.name ? -1 : 1
    }).map(purpose => {
      return purpose.name
    })

    materials.current = props.materials.map(material => {
      return material.name
    })

  }, [props.customerRef,
      props.purposes,
      props.materials,
      props.city]
  )

  props.cities.sort((cityA, cityB) => {
    return cityA.name < cityB.name ? -1 : 1
  })

  props.materials.sort((materialA, materialB) => {
    return materialA.name < materialB.name ? -1 : 1
  })

  const pricingModelUtilities = new PricingModelUtilities(props.pricing)

  const calculatePrice = rowData => {
    if (customerRef.current && rowData.material && rowData.loads) {
      rowData.price = pricingModelUtilities.getPrice(rowData.loads, customerRef.current.data().pricingModel, rowData.material, new Date(), cityRef.current.name)
      return
    }
    rowData.price = null
  }

  const openDupDialog = (newRow) => {
    newRowData.current = newRow
    setOpenDup(true)
  }

  const onCloseDupDialog = (result) => {
    if (result) {
      addRow(newRowData.current)
    }
    setOpenDup(false)
  }

  const addRow = (newData) => {
    const newArray = [...props.lineItems, newData]
    props.lineItemsChanged(newArray)
  }

  const isEditable = () => {
    if (!props.customerRef || !props.city) {
      return undefined
    }
    return newData => 
      new Promise((resolve, reject) => {
        setTimeout(async () => {
          possibleDups.current = await props.orderUtilities.findPossibleDuplicate(customerRef.current.data().name, newData.deliveryAddress, newData.material)
          if (possibleDups.current.length > 0) {
            openDupDialog(newData)
            resolve()
            return
          }
          if (newData.material === "blonde mason") {
            if (!props.isOkToAddBlondMasonSand()) {
              alert("Orders for blonde mason sand can not contain lines for other materials.")
              resolve()
              return
            }
          } else {
            if (props.isBlondMasonSandOrder()) {
              alert("Orders for blonde mason sand can not contain lines for other materials.")
              resolve()
              return
            }
          }

          const newArray = [...props.lineItems, newData]
          props.lineItemsChanged(newArray)
          resolve()
        }, 10)
      })
  }

  const [columns] = useState([
    { title: 'Delivery Address', 
      field: 'deliveryAddress',
      validate: rowData => {
        return rowData.deliveryAddress ? true : false
      }
    },
    { 
      title: 'Material', 
      field: 'material',
      initialEditValue: 'c/s',
      validate: rowData => {
        calculatePrice(rowData)
        return rowData.material ? true : false
      },
        editComponent: prop2 => <Autocomplete
        id="material"
        size="small"
        options={materials.current}
        autoSelect={true}
        autoComplete={true}
        autoHighlight={true}
        openOnFocus={true}
        selectOnFocus={true}
        defaultValue={'c/s'}
        onChange={(event, value) => {
          prop2.rowData.material = value
        }}
        renderInput={(params) => <TextField {...params} label="Material" required={true} />}
      />
    },
    { 
      title: 'Purpose', 
      field: 'purpose',
      initialEditValue: '',
      editComponent: prop2 => <Autocomplete
        id="purpose"
        size="small"
        options={purposes.current}
        autoSelect={true}
        autoComplete={true}
        autoHighlight={true}
        openOnFocus={true}
        selectOnFocus={true}
        value={prop2.rowData.purpose ? prop2.rowData.purpose : undefined}
        onChange={(event, value) => {prop2.rowData.purpose = value ? value : undefined}}
        renderInput={(params) => <TextField {...params} label="Purpose" required={true} />}
      />
    },
    { 
      title: '# Loads', 
      field: 'loads',
      validate: rowData => {
        calculatePrice(rowData)
        return rowData.loads ? true : false
      }
    },
    { 
      title: 'Price', 
      field: 'price',
      validate: rowData => {
        return rowData.price ? true : false
      }
    },
    { title: 'Bill Address', 
      field: 'billToAddress',
    },
    { title: 'Priority', 
      field: 'priority',
      type: 'boolean'
    }
  ])

  return (
    <div>
      <PossibleDuplicatesDialog 
        open={openDup} 
        onClose={onCloseDupDialog} 
        firebase={props.firebase}
        db={props.db}
        customerRefs={props.customerRefs}
        materials={props.materials}
        purposes={props.purposes}
        cities={props.cities}
        subdivisions={props.subdivisions}
        pricing={props.pricing}
        drivers={props.drivers}
        rowColorMap={props.rowColorMap}
        ordersWithRefs={possibleDups.current}
        orderUtilities={props.orderUtilities}
      />
      <MaterialTable
        icons={tableIcons}
        title="Line Items"
        columns={columns}
        data={props.lineItems}
        tableRef={props.tableRef}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          rowStyle: rowData => ({
            backgroundColor: props.rowColorMap.get(rowData.purpose ? rowData.purpose.toUpperCase() : null)
          }) 

        }}
        editable={{
          onRowAdd: isEditable(),
            // new Promise((resolve, reject) => {
            //   setTimeout(() => {
            //     const newArray = [...props.lineItems, newData]
            //     props.lineItemsChanged(newArray)
            //     resolve()
            //   }, 10)
            // }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...props.lineItems]
                const index = oldData.tableData.id
                dataUpdate[index] = newData
                props.lineItemsChanged([...dataUpdate])
                resolve()
              }, 10)
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...props.lineItems]
                const index = oldData.tableData.id
                dataDelete.splice(index, 1)
                props.lineItemsChanged([...dataDelete])
                resolve()
              }, 10)
            }),
        }}
      />    
    </div>
  )
}