import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import DriverUtilities from './DriverUtilities'

DriversAutoComplete.propTypes = {
  drivers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  driver:PropTypes.string.isRequired
}

export default function DriversAutoComplete(props) {

  const driversListRef =useRef(undefined)
  const activeDrivers = DriverUtilities.limitDriversToActiveDrivers(props.drivers)
  driversListRef.current = DriverUtilities.performDefaultSortForDriverNames(
    activeDrivers.map(driver => {
      return driver.name.trim()
    })
  )

  useEffect(() => {
    const activeDrivers = DriverUtilities.limitDriversToActiveDrivers(props.drivers)
    driversListRef.current = DriverUtilities.performDefaultSortForDriverNames(
      activeDrivers.map(driver => {
        return driver.name.trim()
      })
    )
  }, [props.drivers])

  return (<Autocomplete
    id="drivers"
    size="small"
    options={driversListRef.current}
    autoSelect={true}
    autoComplete={true}
    autoHighlight={true}
    openOnFocus={true}
    selectOnFocus={true}
    onChange={(event, value) => props.onChange(event, value)}
    renderInput={(params) => <TextField {...params} label="Driver" variant="standard" />}
  /> )

}