import React, {forwardRef, useEffect, useRef, useState } from 'react'
import MaterialTable from 'material-table'
import { Button } from '@material-ui/core'
import TrucksUtilities, {performDefaultSortForTrucksRefs} from '../common/TrucksUtilities'
import DialogForTruckInspection from './DialogForTruckInspection'
import DialogForTruckMaintenance from './DialogForTruckMaintenance'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function testDateInCustomFilterAndSearch(term, dateFromRow) {
  if(!(term instanceof Date)) {
    return false
  }
  if (!dateFromRow) {
    return false
  }
  const filterDate = term
  const rowDate = dateFromRow
  filterDate.setHours(0,0,0,0)
  rowDate.setHours(0,0,0,0)
  return filterDate.getTime() === rowDate.getTime()
}


export default function TrucksTable(props) {

  const trucksQueryRef = useRef(undefined)
  const trucksUtilitiesRef = useRef(undefined)
  const [trucksWithRefs, setTrucksWithRefs] = useState(undefined)
  const [displayTruckInspection, setDisplayTruckInspection] = useState(undefined)
  const [displayTruckMaintenance, setDisplayTruckMaintenance] = useState(undefined)

  useEffect(() => {
    trucksQueryRef.current = props.db.collection('trucks')

    let trucksUnsubscribe = trucksQueryRef.current
      .onSnapshot(querySnapshot => {
        const tempTrucksWithRefs = querySnapshot.docs
        trucksUtilitiesRef.current = new TrucksUtilities(
          props.firebase, 
          props.db, 
          tempTrucksWithRefs)
        const sortedTrucks = performDefaultSortForTrucksRefs(tempTrucksWithRefs)
        const convertedTrucks = sortedTrucks.map(tempTruckWithRef => {
            return trucksUtilitiesRef.current.convertForTruckTable(tempTruckWithRef)
        })
        setTrucksWithRefs(convertedTrucks)
      })
    return () => {
      trucksUnsubscribe()
    }
  }, [props.firebase,
    props.db]
  )

  function showTruckInspection(rowData) {
    setDisplayTruckInspection(rowData)
  }

  function closeTruckInspection() {
    setDisplayTruckInspection(undefined)
  }

  function showTruckMaintenance(rowData) {
    setDisplayTruckMaintenance(rowData)
  }

  function closeTruckMaintenance() {
    setDisplayTruckMaintenance(undefined)
  }

  function testDateInCustomFilterAndSearch(term, dateFromRow) {
    if(!(term instanceof Date)) {
      return false
    }
    if (!dateFromRow) {
      return false
    }
    const filterDate = term
    const rowDate = dateFromRow
    filterDate.setHours(0,0,0,0)
    rowDate.setHours(0,0,0,0)
    return filterDate.getTime() === rowDate.getTime()
  }

  if (!trucksWithRefs) {
    return <div></div>
  }

  if (displayTruckMaintenance) {
    return (<DialogForTruckMaintenance 
      truck={displayTruckMaintenance.truck}
      onClose={closeTruckMaintenance}
      db={props.db}
      firebase={props.firebase}
    />)
  }  

  if (displayTruckInspection) {
    return (<DialogForTruckInspection 
      truck={displayTruckInspection.truck}
      truckInspectionId={displayTruckInspection.lastInspectionId}
      onClose={closeTruckInspection}
      db={props.db}
      firebase={props.firebase}
    />)
  }  

  return (
    <MaterialTable
      icons={tableIcons}
      title="Trucks"
      columns={[
        { field: 'ref', hidden: true },
        { 
          title: 'Number', 
          field: 'truck',
          render: rowData => {
            return <Button variant="contained" color="primary" onClick={() => showTruckMaintenance(rowData)}>{rowData.truck}</Button>
          } 
        },
        { 
            title: 'Last Inspection', 
            field: 'lastInspectionDate',
            type: 'date',
            customFilterAndSearch: (term, rowData) => {
              return this.testDateInCustomFilterAndSearch(term, rowData.lastInspectinDate)
            },
            render: rowData => {
              if (!rowData.lastInspectionDate) return ''
              if (rowData.lastInspectionId == 0) {
                return ''
              }
              return <Button variant="contained" color="primary" onClick={() => showTruckInspection(rowData)}>{rowData.lastInspectionDate.toLocaleDateString()}</Button>
            } 
          },
      ]}
      data={trucksWithRefs}  
      options={{
        filtering: true,
        search: true,
        sorting: true,
        paging: false,
        addRowPosition: 'first'
      }}
      editable={{
        onRowAdd: async newData => {
          const dataWithoutRef = {...newData}
          delete dataWithoutRef.ref
          trucksUtilitiesRef.current.add(dataWithoutRef).catch(err => {
              alert(err)
            })
        }
      }}
    />
  )

}