import React, {useState} from 'react'
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers';
import DriversAutoComplete from '../common/DriversAutoComplete'

ConvertOrderToDispatch.propTypes = {
  row: PropTypes.object.isRequired,
  orderUtilities: PropTypes.object.isRequired,
  drivers: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default function ConvertOrderToDispatch(props) {

  const onClose = props.onClose
  const [dispatchAsPreload, setDispatchAsPreload] = useState(false)

  const tempDate = props.row.anticipqatedDeliveryDate 
    ? props.row.anticipatedDeliveryDate
    : new Date()
  console.log(props.row.anticipatedDeliveryDate)
  const [driver, setDriver] = useState(props.row.driver)
  const [anticipatedDeliveryDate, setAnticipatedDeliveryDate] = useState(tempDate)

  const close = () => {
    onClose()
  }

  const onCancel = () => {
    close()
  }

  const onConvertToDispatch = async () => {
    const data = { ...props.row }
    delete data.ref 
    delete data.tableData   
    // date will js date if not overridden but 
    // moment date if overridden
    let tempDate = anticipatedDeliveryDate;
    if (!(tempDate instanceof Date)) {
      tempDate = anticipatedDeliveryDate.toDate()
    }
    let dispatchRoutine = props.orderUtilities.dispatch
    if (dispatchAsPreload) {dispatchRoutine = props.orderUtilities.dispatchAsPreload}
    dispatchRoutine(props.row.ref, data, driver, tempDate)
      .then(() => {
        close()
      })
      .catch(e => {
        alert(e.message)
      })
  }

  const handleDriverChange = (event, value) => {
    setDriver(value)
  }

  const handlePreloadChange = (event) => {
    let newAnticipatedDeliveryDate = anticipatedDeliveryDate
    newAnticipatedDeliveryDate.setTime(anticipatedDeliveryDate.getTime() + (24 * 60 * 60 * 1000))
    setAnticipatedDeliveryDate(newAnticipatedDeliveryDate)
    setDispatchAsPreload(!dispatchAsPreload)
  }

  return (
    <Dialog open={true} onClose={close} disableBackdropClick={true} >
      <DialogTitle id="simple-dialog-title">Dispatch</DialogTitle>
      <DialogContent>
        <div>
          <DriversAutoComplete
            id="driver"
            size="small"
            driver={driver}
            onChange={handleDriverChange} 
            drivers={props.drivers}
          />
        </div>
        <div>
          <KeyboardDatePicker 
            value={anticipatedDeliveryDate} 
            onChange={date => setAnticipatedDeliveryDate(date)} 
            format="MM/DD/YYYY"
            label="Anticipated Delivery Date"
          />
        </div>
        <div>
          <FormControlLabel
            value="Preload"
            control={<Checkbox 
              checked={dispatchAsPreload} 
              onChange={handlePreloadChange} 
              name={dispatchAsPreload} 
              label={"preload"}/>}
            label="Preload"
            labelPlacement="start"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConvertToDispatch} color="primary">
          Dispatch
        </Button>
      </DialogActions>
    </Dialog>
  )
}
