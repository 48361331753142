export default class DataUtilities {
  removeWhitespace = (sourceString) => {
    const sourceTokens = sourceString.split(' ')
    const result = sourceTokens.filter(token => {
      return token === '' ? false : true
    }).map(token => {
      return token.trim()
    })
    return result.join(' ')
  }    
}