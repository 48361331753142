import React, { useState, useEffect, useRef } from 'react'
import OrderUtilities from '../common/OrderUtilities';
import PropTypes from 'prop-types';
import _ from 'lodash'

UploadInvoicing.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  customerRefs: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired, 
  pricing: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired
}

export default function UploadInvoicing(props) {

  const [selectedFile, setSelectedFile] = useState(undefined)

  const orderUtilitiesRef = useRef(undefined)

  const propertiesInInputFile = [
    'customer',
    'orderDate',
    'po',
    'actualDeliveryDate',
    'requestedDeliveryDate',
    'material',
    'purpose',
    'loads',
    'billToAddress',
    'price',
    'notes',
    'invoice',
    'contact',
    'contactEmail',
    'ref'
  ]

  const columnMap = propertiesInInputFile.map((property,index) => {
    return {columnIndex: index, property: property}
  })


  useEffect(() => {

    const areaMap = _.keyBy(props.cities, (o) => { return o.area.trim() })
    const citiesMap = _.keyBy(props.cities, (o) => { return o.name.trim() })
    const subdivisionsMap = _.keyBy(props.subdivisions, (o) => { return o.trim() })
    const materialMap = _.keyBy(props.materials, (o) => { return o.name.trim() })
    const purposeMap = _.keyBy(props.purposes, (o) => { return o.name.trim() })

    orderUtilitiesRef.current = new OrderUtilities(
      props.firebase, 
      props.db,
      areaMap,
      props.customerRefs,
      citiesMap,
      subdivisionsMap,
      materialMap,
      purposeMap,
      props.pricing
    )

  }, [
    props.firebase,
    props.db,
    props.pricing,
    props.cities,
    props.customerRefs,
    props.materials,
    props.purposes,
    props.subdivisions
  ])

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const convertLineToObject = (line => {
    const lineAsArray = line.trim().split(',')
    const result = {}
    columnMap.forEach(column => {
      const value = lineAsArray[column.columnIndex] ? lineAsArray[column.columnIndex] : ''
      result[column.property] = value.trim().replace(/"/g, '')
    })
    return result
  })

  const processFile = async (fileContents) => {
    const lines = fileContents.split('\n')
    // audit lines
    const badLines = lines.filter(line => {
      if (line === '') return false
      const input = convertLineToObject(line)
      return !input.ref
    })
    if (badLines.length > 0) {
      alert(`One or more lines do not contain original reference id`)
      console.log(propertiesInInputFile)
      console.log(badLines)
      return
    }
    // remove column names
    lines.shift()
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i]
      if (!line) continue
      const index = i
      const input = convertLineToObject(line)
      if (input.ref) {
        try {
          await orderUtilitiesRef.current.processInvoiceUpdates(input)
        } catch(err) {
            alert(`Error on line ${index+2},${input.ref} - ${err}`)
        }  
      }
    }
  }

  const readFile = () => {
    var fr=new FileReader()
    fr.onload= async () => { 
      await processFile(fr.result)
      props.onClose()
    }
    fr.readAsText(selectedFile, 'UTF-8')
  }

  return (
    <div> 
        <h3> 
        Choose a file to upload
        </h3> 
        <div> 
            <input type="file" onChange={onFileChange} /> 
            <button onClick={readFile}> 
              Upload! 
            </button> 
        </div> 
    </div> 
  )
}