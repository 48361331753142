import { PricingUtilitiesError } from './errorMessages'
import _ from 'lodash'

export default class PricingModelUtilities {

  constructor(prices) {
    this.prices = prices
    this.pricingMap = _.keyBy(prices, (o) => { return o.name.trim() })
    this.defaultModel = this.getByName('default')
    if (!this.defaultModel) {
      throw new Error(PricingUtilitiesError.defaultPricingModelNotFound)
    }
  }

  getByName = (name) => {
    return this.pricingMap[name]
  }

  getCurrentPricesFromPricingModel(model, orderDateParam, city) {
    const effectiveMaterialPrices = model.prices.filter(price => {
      let priceDate = new Date(price.effectiveDate)
      let orderDate = new Date(orderDateParam)
      return priceDate.getTime() <= orderDate.getTime()
    })
    const defaultPriceForAllCities = effectiveMaterialPrices.filter(price => {
      if (price.city == '') {
        return true
      }
    })
    const effectivePricesForCity = effectiveMaterialPrices.filter(price => {
      if (city === price.city) {
        return true
      }
    })
    if (effectivePricesForCity.length > 0) {
      return effectivePricesForCity[effectivePricesForCity.length - 1]
    }
    return defaultPriceForAllCities[defaultPriceForAllCities.length - 1]
  }

  getPrice = (loads, modelName, material, orderDate, city) => {
    const model = this.getByName(modelName)
    if (model) {
      return this.calculatePriceForLoad(model, loads, material, orderDate, city)
    }
    return this.calculatePriceForLoad(this.defaultModel, loads, material, orderDate)
  }

  calculatePriceForLoad(model, loads, material, orderDate, city) {
    const currentPrices = this.getCurrentPricesFromPricingModel(model, orderDate, city)
    const pricesForMaterial = this.findPricesByMaterial(currentPrices.materials, loads, material, orderDate)
    if (pricesForMaterial.length === 1) {
      return loads * pricesForMaterial[0]
    }
    let price = 0
    let loadCounter = loads
    if (loadCounter === pricesForMaterial.length) {
      return loads * pricesForMaterial[pricesForMaterial.length - 1]
    }
    while (loadCounter > (pricesForMaterial.length)) {
      price += pricesForMaterial.length * pricesForMaterial[pricesForMaterial.length - 1]
      loadCounter -= pricesForMaterial.length
    }
    const loadPriceToUse = (loadCounter - 1) < 0 ? 0 : loadCounter - 1
    price += loadCounter * pricesForMaterial[loadPriceToUse]
    return price
  }

  findPricesByMaterial = (currentPrices, loads, material, orderDate) => {
    let materialLoadPrices = this.findLoadPricesForMaterial(currentPrices, material)
    if (!materialLoadPrices) {
      const defaultLoadPrices = this.getCurrentPricesFromPricingModel(this.defaultModel, orderDate)
      materialLoadPrices = this.findLoadPricesForMaterial(defaultLoadPrices.materials, material)
    }
    return materialLoadPrices
  }

  findLoadPricesForMaterial = (currentPrices, material) => {
    for (let i = 0; i < currentPrices.length; i++) {
      if (currentPrices[i].material.includes(material)) {
        return currentPrices[i].loads
      }
    }
  }

  addPricingModel = async (pricingModel) => {
    // procing model must have a name
    if (!pricingModel.name) {
      throw new Error(PricingUtilitiesError.pricingModelMustHaveName)
    }
    // model must have at least one price
    if (!pricingModel.prices ||
        !pricingModel.prices.length > 0) {
          throw new Error(PricingUtilitiesError.pricingModelMustHaveOnePrice)
    }
    // model must have an effective date for prices
    pricingModel.prices.forEach(price => {
      if (!price.effectiveDate) {
        throw new Error(PricingUtilitiesError.pricingModelMustHaveEffectiveDate)
      }  
    })
    return this.db.collection('pricing').doc(pricingModel.name).set(pricingModel);
  }

}