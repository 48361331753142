import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import OrdersTable from './OrdersTable'
import _ from 'lodash'
import OrderUtilities from '../common/OrderUtilities'
import { performDefaultSortForOrders } from '../common/OrderUtilities'

DeliveredOrders.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  customerRefs: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  pricing: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  rowColorMap: PropTypes.object.isRequired
}

const columnsForTable = [
  'ref',
  'customer',
  'driver',
  'orderDate',
  'billToAddress',
  'city',
  'loads',
  'heavy',
  'material',
  'purpose',
  'price',
  'contact',
  'contactEmail',
  'po',
  'notes',
  'preloadedDate',
  'actualDeliveryDate',
  'deliveryLocation',
  'deliveryNotes',
  'deliveryAddress'
]

export default function DeliveredOrders(props) {

  const orderUtilitiesRef = useRef(undefined)
  const orderQueryRef = useRef(undefined)
  const [ordersWithRefs, setOrdersWithRefs] = useState(undefined)

  useEffect(() => {

    orderQueryRef.current = props.db.collection('orders')
      .where('delivered', '==', true)
      .where('readyToInvoice', '==', false)

    const areaMap = _.keyBy(props.cities, (o) => { return o.area.trim() })
    const citiesMap = _.keyBy(props.cities, (o) => { return o.name.trim() })
    const subdivisionsMap = _.keyBy(props.subdivisions, (o) => { return o.trim() })
    const materialMap = _.keyBy(props.materials, (o) => { return o.name.trim() })
    const purposeMap = _.keyBy(props.purposes, (o) => { return o.name.trim() })
  
    orderUtilitiesRef.current = new OrderUtilities(
      props.firebase, 
      props.db,
      areaMap,
      props.customerRefs,
      citiesMap,
      subdivisionsMap,
      materialMap,
      purposeMap,
      props.pricing
    )

    let ordersUnsubscribe = orderQueryRef.current
      .onSnapshot(querySnapshot => {
        const tempOrdersWithRefs = querySnapshot.docs.map(orderRef => {
          return orderUtilitiesRef.current.convertOrderForOrderTable(orderRef)
        })
        setOrdersWithRefs(performDefaultSortForOrders(tempOrdersWithRefs))
      })
    return () => {
      ordersUnsubscribe()
    }
  }, [props.firebase,
    props.db,
    props.query,
    props.customerRefs, 
    props.materials, 
    props.purposes, 
    props.cities, 
    props.pricing,
    props.actions,
    props.subdivisions]
  )
  
  return (
    <OrdersTable firebase={props.firebase}
      db={props.db}
      title={"Delivered Orders"}
      columns={columnsForTable}
      actions={['ready','readyToInvoice']}
      customerRefs={props.customerRefs}
      materials={props.materials}
      purposes={props.purposes}
      cities={props.cities}
      subdivisions={props.subdivisions}
      pricing={props.pricing}
      drivers={props.drivers}
      rowColorMap={props.rowColorMap}
      ordersWithRefs={ordersWithRefs}
      orderUtilities={orderUtilitiesRef.current}
      paging={true}
      pageSize={500}
    />
  )

}