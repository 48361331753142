import React from 'react'
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import PoOrders from './PoOrders'

DialogForPoOrders.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  customerRefs: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  pricing: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  rowColorMap: PropTypes.object.isRequired,
  po: PropTypes.string.isRequired,
  customer: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default function DialogForPoOrders(props) {

  const onClose = props.onClose

  return (
    <Dialog fullScreen open={true} onClose={props.onClose} fullWidth={true} disableBackdropClick={true} >
      <DialogTitle id="simple-dialog-title">Orders For PO {props.po}</DialogTitle>
      <DialogContent>
        <PoOrders 
          firebase={props.firebase} 
          db={props.db} 
          customerRefs={props.customerRefs} 
          materials={props.materials}
          purposes={props.purposes}
          cities={props.cities}
          subdivisions={props.subdivisions}
          pricing={props.pricing}
          drivers={props.drivers}
          rowColorMap={props.rowColorMap}
          po={props.po}
          customer={props.customer}
        /> 
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
