import React from 'react'
import PropTypes from 'prop-types'
import { TextField, Button, Container } from '@material-ui/core'

LoginPage.propTypes = {
  firebaseauth: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired
}

export default function LoginPage(props) {

  const doSubmit = (event) => {
    const email = event.target['email'].value
    const password = event.target['password'].value
    props.firebaseauth.signInWithEmailAndPassword(email, password)
      .catch(error => {
        alert(error)
      });    
    event.preventDefault()
  }

  if (props.show === false) {
    return null
  }
  return (
    <Container maxWidth='sm'>
        <form name="form" onSubmit={doSubmit}>
            <TextField
              id="email"
              label="Enter your email address"
              name="email" 
              fullWidth
            />
            <TextField
              id="password"
              label="Enter your password"
              type="password"
              name="password" 
              fullWidth
            />
          <div>
            <Button variant="contained" color="primary" type="submit">Login</Button>
          </div>
        </form>
    </Container>
  );
}
