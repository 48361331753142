import React, {forwardRef} from 'react'
import PropTypes from 'prop-types';
import MaterialTable from 'material-table'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CustomerUtilities from '../common/CustomerUtilities'
import { CsvBuilder } from "filefy";
import { convertDateToDateString } from '../common/FirestoreUtilities'


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

CustomersTable.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  customerRefs: PropTypes.array.isRequired,
  pricingModels: PropTypes.array.isRequired
}

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function CustomersTable(props) {

  const myExportCsv = (columns, data) => {
    const fileName = prompt('Please enter a filename without an extension', 'dwr')
    const builder = new CsvBuilder(fileName + ".csv");
    const convertedColumns = columns.filter(column => {
      return column.title ? true : false
    })
    convertedColumns.push({ title: 'Ref (do not modify)', field: 'ref'})
    const convertedRows = []
    data.forEach(row => {
      const convertedRow = []
      convertedColumns.forEach(column => {
        let value = row[column.field]
        if (value instanceof Date) {
          value = convertDateToDateString(props.firestore, value)
        }
        convertedRow.push(value)
      })
      convertedRows.push(convertedRow)
    })
    builder
      // .setDelimeter(this.props.exportDelimiter)
      .setDelimeter(",")
      .setColumns(convertedColumns.map((columnDef) => columnDef.title))
      .addRows(convertedRows)
      .exportFile();
  }

  const customersWithRefs = props.customerRefs.map(customerRef => {
    return {ref: customerRef.id, ...customerRef.data()}
  }).sort((customerA, customerB) => {
    return customerA.name < customerB.name ? -1 : 1
  })
  const pricingModelMap = props.pricingModels.reduce((obj, item) => {
    obj[item.name] = item
    return obj
  }, {})
  const customerUtilities = new CustomerUtilities(props.firebase, props.db, props.customerRefs)
  return (
    <MaterialTable
      icons={tableIcons}
      title="Customers (Please do not use commas in the customer name)"
      columns={[
        { field: 'ref', hidden: true },
        { title: 'Name', field: 'name' },
        { title: 'Pricing Model', 
          field: 'pricingModel',
          editComponent: prop2 => <Autocomplete
            id="pricingModel"
            size="small"
            options={props.pricingModels}
            getOptionLabel={(option) => option.name}
            value={pricingModelMap[prop2.value]}
            onChange={(event, value) => {prop2.rowData.pricingModel = value.name}}
            renderInput={(params) => <TextField {...params} label="Pricing Model" variant="outlined" />}
          /> },
        { title: 'Requires PO', field: 'requiresPO', type: 'boolean' },
        { title: 'PO Prefix', field: 'poPrefix'},
        { title: 'Subdivision', 
          field: 'subdivision',
          editComponent: prop2 => <Autocomplete
            id="subdivision"
            size="small"
            options={props.subdivisions}
            value={prop2.rowData.subdivision}
            onChange={(event, value) => {prop2.rowData.subdivision = value}}
            renderInput={(params) => <TextField {...params} label="Subdivision" variant="outlined" />}
          /> },
          { title: 'Default Notes', field: 'defaultNotes'},
          { title: 'Default Contact', field: 'defaultContact'},
          { title: 'Default Email Or Phone', field: 'defaultEmailPhone'},
      ]}
      data={customersWithRefs}  
      options={{
        filtering: true,
        search: true,
        sorting: true,
        paging: false,
        addRowPosition: 'first',
        exportCsv: myExportCsv,
        exportButton: true,
      }}
      editable={{
        onRowAdd: async newData => {
            customerUtilities.addCustomer(newData).catch(err => {
              alert(err)
            })
        },
        onRowUpdate: async (newData, oldData) =>{
          console.log("Old data")
          console.log(oldData)
          console.log("New data")
          console.log(newData)
          customerUtilities.updateCustomer(oldData.ref, newData).catch(err => {
            alert(err)
          })
        },
        onRowDelete: async oldData => {
          customerUtilities.deleteCustomer(oldData.ref).catch(err => {
            alert(err)
          })
        },
      }}
    />
  )
}