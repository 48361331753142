import React, {useState} from 'react'
import PropTypes from 'prop-types';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'

SplitOrder.propTypes = {
  row: PropTypes.object.isRequired,
  orderUtilities: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default function SplitOrder(props) {

  const onClose = props.onClose

  const [splitLoads, setSplitLoads] = useState(null)

  const close = () => {
    onClose()
  }

  const onCancel = () => {
    close()
  }

  const onSplitLoad = async () => {
    try {
      await props.orderUtilities.splitOrder(props.row, splitLoads)
    } catch (error) {
      alert(error)
      return
    }
    close()
  }

  const handleSplitLoadsChange = (event) => {
    setSplitLoads(event.target.value)
  }

  return (
    <Dialog open={true} onClose={close}>
      <DialogTitle id="simple-dialog-title">Loads to Dispatch</DialogTitle>
      <DialogContent>
        <TextField
          id="splitLoads"
          size="small"
          placeholder="Enter loads to dispatch"
          inputProps={{autoFocus: true}}
          label="Split Loads"
          variant="outlined"
          onChange={handleSplitLoadsChange} 
          fullWidth
        />
      </DialogContent>
      <DialogActions>
          <Button color="primary" onClick={onCancel}>Cancel</Button>
          <Button color="primary" onClick={onSplitLoad}>Split</Button>
      </DialogActions>
    </Dialog>
  )
}
