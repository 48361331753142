export const FirestoreUtilitiesError = {
  iso8601DateStringRequired:'ISO 8601 string required',
  objectPassedIsNotADate: "Date conversion requires a JS or Moment date"
}

// Order Utilities - add order
export const OrderUtilitiesError =  {
  orderCustomerRequired: 'Valid customer required for new order.',
  orderMinimumLoads: 'Order must contain at least 1 load.',
  orderRequiresAddress: 'Address required for new order',
  dispatchLoadMatchExceed: 'Can not dispatch more than 2 loads at a time',
  dispatchLoadMinimumNotMet: 'Order must contain at least 1 load to dispatch',
  orderNotReadyToDispatch: 'Order is not ready to be dispatched',
  driverRequiredToDispatch: 'Driver is required to dispatch',
  anticipatedDeliveryDateRequiredToDispatch: 'Anticipated delivery date is required for dispatching',
  addressRequiredForNewOrder: 'Address required for new order',
  deliveryAddressRequiredForNewOrder: 'Delivery address required for new order',
  validCityRequiredFornewOrder: 'Vaid city required for new order',
  validSubdivisionRequiredFornewOrder: 'Vaid subdivision (if specified) is required for new order',
  validMaterialRequiredFornewOrder: 'Valid material required for new order',
  orderPriceRequiredForNewOrders: 'Order price required for new order',
  orderPurposeIsNotValid: 'Purpose must be valid',
  cantConvertReadyOrderToReady: 'Order is already in ready to dispatch state',
  cantConvertDeliveredOrderToFuture: 'Delivered order can not be set back to future',
  readyOrderMustHaveAnticipatedDeliveryDate: 'Order must have anticipated delivery date to be ready',
  loadsForNewLineRequired: 'Number of loads must be spcified for split',
  notEnoughLoadsToSplit: 'An order must have more than one load to split',
  splitRequestExceedsLoads: 'The requested loads for split exceeds or equals current loads',
  splitRequestNotNumeric: 'The requested loads for split is not a number',
  orderMustBeDeliveredToBeInvoiced: 'Order can not be invoiced if not delivered',
  poRequiredForOrder: 'Customer requires PO - enter EPO, NPO, or a PO number',
  poNotAllowedForOrder: 'Customer does not use POs - remove PO number',
  orderNotFound: 'Order to update not found',
  customerInUpdateDoesNotMatchOrder: 'Customer in update does not match original order',
  materialInUpdateDoesNotMatchOrder: 'Material in update does not match original order',
  purposeInUpdateDoesNotMatchOrder: 'Purpose in update does not match original order',
  isNaN: 'Entered value is not a number',
}

export const PricingUtilitiesError =  {
  defaultPricingModelNotFound: 'Default pricing model not found in database',
  pricingModelMustHaveName: 'New pricing model must have a name',
  pricingModelMustHaveOnePrice: 'New pricing model must have at least one price',
  pricingModelMustHaveEffectiveDate: 'New pricing model must have an effective date'
}

export const DriverUtilitiesError =  {
  driverNotFound: 'The driver on the delivered order was not found in the database.',
  removeRefForUpdate: 'Remove the ref property for updating'
}

export const SubdivisionUtilitiesError =  {
  removeRefForUpdate: 'Remove the ref property for updating'
}

export const PitsUtilitiesError =  {
  removeRefForUpdate: 'Remove the ref property for updating'
}

export const TrucksUtilitiesError =  {
  removeRefForUpdate: 'Remove the ref property for updating'
}

export const CitiesUtilitiesError =  {
  areaIsRequiredS: 'An area is required for a city'
}

export const customerMustHaveName = 'New customer must have a name'
