import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Tabs, Tab } from '@material-ui/core'
import TruckInspectionsTabContent from './TruckInspectionsTabContent'
import FuelPurchasesTabContent from './FuelPurchasesTabContent'
import GreasingsTabContent from './GreasingsTabContent'
import MinorRepairsTabContent from './MinorRepairsTabContent'
import TireChangesTabContent from './TireChangesTabContent'
import OilChangesTabContent from './OilChangesTabContent'
import WashesTabContent from './WashesTabContent'

DialogForTruckMaintenance.propTypes = {
    onClose: PropTypes.func.isRequired,
    db: PropTypes.object.isRequired,
    firebase: PropTypes.object.isRequired,
    truck: PropTypes.string.isRequired
}

export default function DialogForTruckMaintenance(props) {

    const onClose = props.onClose

    const truckMaintenanceCategories = [
        "Inspections",
        "Fuel Purchases",
        "Greasings",
        "Minor Repairs",
        "Tire Changes",
        "Oil Changes",
        "Washes",
    ]

    const [dialogTitle, setDialogTitle] = useState(undefined)
    const [value, setValue] = useState("Inspections")

    useEffect(() => {
        setDialogTitle(`Truck Maintenance Records ${props.truck}`)
    }, [props.truck])

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
    }

    if (!dialogTitle) return (<div></div>)

    return (
        <Dialog open={true} onClose={props.onClose} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle id="simple-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={12}>
                        <Tabs value={value} 
                            onChange={handleTabChange}
                            orientation="horizontal"
                            variant="scrollable" >
                            {truckMaintenanceCategories.map(category => (
                                <Tab key={category} label={category} value={category}/>
                        ))}
                        </Tabs>
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={12}>
                        {(value == "Inspections") && <TruckInspectionsTabContent truck={props.truck} db={props.db} firebase={props.firebase} />}
                        {(value == "Fuel Purchases") && <FuelPurchasesTabContent truck={props.truck} db={props.db} firebase={props.firebase} />}
                        {(value == "Greasings") && <GreasingsTabContent truck={props.truck} db={props.db} firebase={props.firebase} />}
                        {(value == "Minor Repairs") && <MinorRepairsTabContent truck={props.truck} db={props.db} firebase={props.firebase} />}
                        {(value == "Tire Changes") && <TireChangesTabContent truck={props.truck} db={props.db} firebase={props.firebase} />}
                        {(value == "Oil Changes") && <OilChangesTabContent truck={props.truck} db={props.db} firebase={props.firebase} />}
                        {(value == "Washes") && <WashesTabContent truck={props.truck} db={props.db} firebase={props.firebase} />}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose} >Close</Button>
            </DialogActions>
        </Dialog> 
    )

}