import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import OrdersTable from './OrdersTable'
import _ from 'lodash'
import OrderUtilities from '../common/OrderUtilities';
import DateUtilities from '../common/DateUtilities';
import { performSortForReadyOrders } from '../common/OrderUtilities';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DriverUtilities from '../common/DriverUtilities'
import { Typography, Tooltip, withStyles } from '@material-ui/core'

ReadyOrders.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  customerRefs: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  purposes: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  pricing: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  rowColorMap: PropTypes.object.isRequired
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const columnsForTable = [
  'ref',
  'customer',
  'subdivision',
  'requestedDeliveryDate',
  'notes',
  'deliveryAddress',
  'city',
  'driver',
  'priority',
  'loads',
  'heavy',
  'material',
  'purpose',
  'contact',
  'contactEmail',
  'po',
  'orderDate',
]

export default function ReadyOrders(props) {

  const orderUtilitiesRef = useRef(undefined)
  const orderQueryRef = useRef(undefined)
  const areaListRef = useRef(undefined)
  const [ orders, setOrders ] = useState(undefined)
  const [value, setValue] = useState('All')
  const dateUtilities = useRef(new DateUtilities())
  const driverListRef = useRef(undefined)

  useEffect(() => {
    let areaList = _.uniq(props.cities.map(city => {
      return city.area.trim()
    }))
    areaListRef.current = areaList.sort((area1, area2) => {
      return area1 < area2 ? -1 : 1
    })  
  }, [props.cities])
  
  useEffect(() => {
    driverListRef.current = DriverUtilities.performDefaultSortForDriverNames(
      props.drivers.filter(driver => {
          return driver.currentlyDriving
      }).map(driver => {
        return driver.name.trim()
      })
    )  
  }, [props.drivers])
  
  useEffect(() => {

    orderQueryRef.current = props.db.collection('orders')
      .where('ready', '==', true)
      .where('delivered', '==', false)
      .where('cancelled', '==', false)
      .where('held', '==', false)

    let hideDate = dateUtilities.current.addBusinessDays(new Date(new Date().toDateString()), 2)
    const areaMap = _.keyBy(props.cities, (o) => { return o.area.trim() })
    const citiesMap = _.keyBy(props.cities, (o) => { return o.name.trim() })
    const subdivisionsMap = _.keyBy(props.subdivisions, (o) => { return o.trim() })
    const materialMap = _.keyBy(props.materials, (o) => { return o.name.trim() })
    const purposeMap = _.keyBy(props.purposes, (o) => { return o.name.trim() })

    orderUtilitiesRef.current = new OrderUtilities(
      props.firebase, 
      props.db,
      areaMap,
      props.customerRefs,
      citiesMap,
      subdivisionsMap,
      materialMap,
      purposeMap,
      props.pricing
    )

    let ordersUnsubscribe = orderQueryRef.current
      .onSnapshot(querySnapshot => {
        let tempOrdersWithRefs = querySnapshot.docs.map(orderRef => {
          return orderUtilitiesRef.current.convertOrderForOrderTable(orderRef)
        })
        tempOrdersWithRefs = performSortForReadyOrders(tempOrdersWithRefs)
        const tempReadyOrdersWithRefs = tempOrdersWithRefs.filter(order => {
          if (order.dispatched) return false
          if ((order.requestedDeliveryDate instanceof Date) &&
            (order.requestedDeliveryDate > hideDate)) {
              return false
            }
          return true
        })
        const tempDispatchedOrdersWithRefs = tempOrdersWithRefs.filter(order => {
          return order.dispatched === true
        })
        const areaOrdersMap = {}
        const areaLoadsByMaterialMap = {}
        areaListRef.current.forEach(area => {
          areaOrdersMap[area.trim()] = []
          areaLoadsByMaterialMap[area.trim()] = new Map()
        })
        areaOrdersMap['All'] = []
        areaLoadsByMaterialMap['All'] = new Map()
        tempReadyOrdersWithRefs.forEach(orderWithRef => {
          areaOrdersMap['All'].push(orderWithRef)
          areaOrdersMap[orderWithRef.area.trim()].push(orderWithRef)
          if (areaLoadsByMaterialMap['All'].has(orderWithRef.material)) {
            let newCount = parseInt(areaLoadsByMaterialMap['All'].get(orderWithRef.material))
            console.log(newCount)
            newCount += parseInt(orderWithRef.loads)
            areaLoadsByMaterialMap['All'].set(orderWithRef.material,newCount)
          } else {
            areaLoadsByMaterialMap['All'].set(orderWithRef.material,parseInt(orderWithRef.loads))
          }
          if (areaLoadsByMaterialMap[orderWithRef.area.trim()].has(orderWithRef.material)) {
            let newCount = parseInt(areaLoadsByMaterialMap[orderWithRef.area.trim()].get(orderWithRef.material))
            newCount += parseInt(orderWithRef.loads)
            areaLoadsByMaterialMap[orderWithRef.area.trim()].set(orderWithRef.material,newCount)
          } else {
            areaLoadsByMaterialMap[orderWithRef.area.trim()].set(orderWithRef.material,parseInt(orderWithRef.loads))
          }
        })
        const driversOrdersMap = {}
        driverListRef.current.forEach(driver => {
          driversOrdersMap[driver.trim()] = []
        })
        driversOrdersMap['All'] = []
        tempDispatchedOrdersWithRefs.forEach(orderWithRef => {
          driversOrdersMap['All'].push(orderWithRef)
          if (orderWithRef.driver) {
            driversOrdersMap[orderWithRef.driver.trim()].push(orderWithRef)
          }
        })
        const areaLabelMap = {}
        props.drivers.filter(driver => {
          return driver.currentlyDriving
        }).forEach(driver => {
          if (driver.area) {
            const driverFirstname = driver.name.split(' ')[0]
            areaLabelMap[driver.area] = `${driver.truck ? driver.truck : ''} - ${driverFirstname}`
          }
        })
        const orders = {
          driversOrdersWithRefs: driversOrdersMap,
          areaOrdersWithRefs: areaOrdersMap,
          areaLoadsByMaterialMap: areaLoadsByMaterialMap,
          areaLabelMap: areaLabelMap
        }
        setOrders(orders)
      })
    return () => {
      ordersUnsubscribe()
    }
  }, [props.firebase,
    props.db,
    props.query,
    props.customerRefs, 
    props.materials, 
    props.purposes, 
    props.cities, 
    props.pricing,
    props.actions,
    props.drivers,
    props.subdivisions]
  )

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  if (!orders) {
    return (<div></div>)
  }

  if (!props.drivers) {
    return (<div></div>)
  }

  return (
    <div>
      <div>
        <Tabs value={value} onChange={handleTabChange} >
          <Tab key={'All'} label={`All (${orders.areaOrdersWithRefs['All'].length})`} value={'All'}/>
          {areaListRef.current.map(area => {
            const driverLabel = orders.areaLabelMap[area] ? orders.areaLabelMap[area] : area
            const areaLabel = `${driverLabel} (${orders.areaOrdersWithRefs[area].length})` 
            return (
              <Tab key={area} label={areaLabel} value={area}/>
              )
            })
          }
        </Tabs>
        <div>
        <Typography color="inherit">
            <HtmlTooltip
                placement="bottom-start"
                title={
                  <React.Fragment>
                    <Typography color="inherit">Loads By Material</Typography>
                    {console.log(orders.areaLoadsByMaterialMap[value])}
                    {Array.from(orders.areaLoadsByMaterialMap[value].entries()).map(entry => {
                      console.log(entry)
                      return ( <div>{entry[0]}={entry[1]}</div> )
                    })}
                  </React.Fragment>
                }
              >
                <div>Total Orders = {orders.areaOrdersWithRefs[value].length}, 
                     Total Loads = {orders.areaOrdersWithRefs[value].reduce((accumulator, currentValue) => { 
                       return accumulator + parseInt(currentValue.loads)},0)}
                </div>
              </HtmlTooltip>
          </Typography>
        </div>
        <OrdersTable
          firebase={props.firebase}
          db={props.db}
          title={"Ready Orders"}
          columns={columnsForTable}
          actions={['future','dispatch', 'cancel', 'hold', 'split']}
          customerRefs={props.customerRefs}
          materials={props.materials}
          purposes={props.purposes}
          cities={props.cities}
          subdivisions={props.subdivisions}
          pricing={props.pricing}
          drivers={props.drivers}
          rowColorMap={props.rowColorMap}
          ordersWithRefs={orders.areaOrdersWithRefs[value]}
          orderUtilities={orderUtilitiesRef.current}
          tabValue={value}
          paging={true}
          pageSize={500}
        />
      </div>
    </div>
  )
}