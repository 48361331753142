import OrdersTable from "../orders/OrdersTable";
import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'

export default function PossibleDuplicatesDialog(props) {
  
  const columnsForTable = [
    'ref',
    'status',
    'customer',
    'subdivision',
    'billToAddress',
    'deliveryAddress',
    'city',
    'orderDate',
    'loads',
    'material',
    'purpose',
    'contact',
    'contactEmail',
    'po',
    'invoice',
    'price',
    'notes',
    'readyDate',
    'dispatchedDate',
    'actualDeliveryDate',
    'readyToInvoiceDate',
    'driver',
    'area',
    'ticket',
    'pit',
    'cancelledDate',
    'heldDate',
  ]
  
  const onCancel = () => {
    props.onClose(false)
  }

  const onContinue = () => {
    props.onClose(true)
  }

  return (
    <Dialog open={props.open} onClose={onCancel} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle id="simple-dialog-title">Possible Duplicate Order(s)</DialogTitle>
      <DialogContent>
        <OrdersTable 
          firebase={props.firebase}
          db={props.db}
          title={"Possible Duplicate Orders"}
          columns={columnsForTable}
          customerRefs={props.customerRefs}
          materials={props.materials}
          purposes={props.purposes}
          cities={props.cities}
          subdivisions={props.subdivisions}
          pricing={props.pricing}
          drivers={props.drivers}
          rowColorMap={props.rowColorMap}
          ordersWithRefs={props.ordersWithRefs}
          orderUtilities={props.orderUtilities}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>Cancel New Order Line</Button>
        <Button color="primary" onClick={onContinue} >Continue New Order Line</Button>
      </DialogActions>
    </Dialog>
  )

}