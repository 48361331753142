import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import DispatchSequence from './DispatchSequence'

DialogForSequencingDispatches.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  rowColorMap: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  orderUtilities: PropTypes.object.isRequired,
  ordersToSequence: PropTypes.array.isRequired,
  driver: PropTypes.object.isRequired
}

export default function DialogForSequencingDispatches(props) {

  const [ordersToSequence, setOrdersToSequence] = useState([...props.ordersToSequence])

  const close = () => {
    props.onClose()
  }

  const onCancel = () => {
    close(false)
  }

  async function updateDispatchSequences() {
    try {
      await props.orderUtilities.updateDispatchSequences(ordersToSequence)
    } catch (error) {
      alert(error.message)
      return
    }
    close(true)
  }

  async function onUpdate() {
    await updateDispatchSequences()
  }

  return (
    <Dialog open={true} onClose={close} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle id="simple-dialog-title">Sequence for Driver - {props.driver}</DialogTitle>
      <DialogContent>
        <DispatchSequence orders={ordersToSequence} rowColorMap={props.rowColorMap} onSequenceChange={setOrdersToSequence} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>Cancel</Button>
        <Button color="primary" onClick={onUpdate} >Update</Button>
      </DialogActions>
    </Dialog>
  )
  
}
