import React, {useState, useRef, useEffect} from 'react'
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, 
         Paper, Tooltip, withStyles, Typography } from '@material-ui/core'
import { DatePicker } from "@material-ui/pickers"
import { convertDateToDateString,
        convertDateToFirestoreTimestamp } from '../common/FirestoreUtilities'
import DriverUtilities from '../common/DriverUtilities'

const sortTotalsByLastName = (totals) => {
  return totals.sort((totalA, totalB) => {
    const lastNameA = totalA.name.substr(totalA.name.indexOf(' ')+1)
    const lastNameB = totalB.name.substr(totalB.name.indexOf(' ')+1)
    return lastNameA.trim() < lastNameB.trim() ? -1 : 1
  })  
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const addDays = (input, days) => {
  const date = new Date(input)
  date.setDate(date.getDate() + days)
  return date
}

const calcInitialDates = () => {
  let endDate = new Date()
  // if it is Wednesday, back up end date to yesterday
  if (endDate.getDay() === 3) {
    endDate = addDays(endDate, -1)
  }
  let startDate = endDate
  // back up start date to a Wednesday
  while (startDate.getDay() !== 3) {
    startDate = addDays(startDate, -1)
  }
  return { initStartDate: startDate, initEndDate: endDate }
}

export default function DriverLoadsForPayroll(props) {

  const { initStartDate, initEndDate } = calcInitialDates() 

  const driverQueryRef = useRef(props.db.collection('drivers'))
  const [driversData, setDriversData] = useState(undefined)
  const [startDate, setStartDate] = useState(initStartDate)
  const [endDate, setEndDate] = useState(initEndDate)

  useEffect(() => {

    let driversUnsubscribe = driverQueryRef.current
      .onSnapshot(querySnapshot => {
          const tempDriverRefs = DriverUtilities.limitDriverRefsToActiveDrivers(querySnapshot.docs)
          const temp = {
            driverRefs: tempDriverRefs,
            totalsByDriverForDateRange: calcTotalsByDriverForDateRange(tempDriverRefs, startDate, endDate)
          }
          setDriversData(temp)
        })  

    return () => {
      driversUnsubscribe()
    }
  }, [])

  const calcTotalsByDriverForDateRange = (driverRefs, startDate, endDate) => {
    const dates = []
    const dateForLoop = new Date(startDate)
    dateForLoop.setHours(0,0,0,0)
    // const adjustedEndDate = addDays(endDate, 1)
    const adjustedEndDate = new Date(endDate)
    adjustedEndDate.setHours(0,0,0,0)
    for (var d = dateForLoop; d <= adjustedEndDate; d = addDays(d,1).setHours(0,0,0,0)) {
      dates.push(new Date(d));
    }
    const result = []
    driverRefs.forEach((driverRef) => {
      if (driverRef.data().name === 'DJ Jones' ||
          driverRef.data().name === 'Dwain Jones') {
            return
          }
      const driverTotals = {}
      const driver = driverRef.data()
      driverTotals.name = driver.name
      driverTotals.doubles = dates.reduce((accumulator, date) => {
        const timeStamp = convertDateToFirestoreTimestamp(props.firebase, date)
        return driver.dailySummaries && 
              driver.dailySummaries[timeStamp] &&
              driver.dailySummaries[timeStamp].doubles ?
          accumulator + parseInt(driver.dailySummaries[timeStamp].doubles) :
          accumulator
      },0)
      driverTotals.hours = dates.reduce((accumulator, date) => {
        const timeStamp = convertDateToFirestoreTimestamp(props.firebase, date)
        return driver.dailySummaries && 
              driver.dailySummaries[timeStamp] &&
              driver.dailySummaries[timeStamp].hours ?
          accumulator + parseFloat(driver.dailySummaries[timeStamp].hours) :
          accumulator
      },0)
      driverTotals.bank = dates.reduce((accumulator, date) => {
        const timeStamp = convertDateToFirestoreTimestamp(props.firebase, date)
        return driver.dailySummaries && 
              driver.dailySummaries[timeStamp] &&
              driver.dailySummaries[timeStamp].bank ?
          accumulator + parseInt(driver.dailySummaries[timeStamp].bank) :
          accumulator
      },0)
      driverTotals.withdraw = dates.reduce((accumulator, date) => {
        const timeStamp = convertDateToFirestoreTimestamp(props.firebase, date)
        return driver.dailySummaries && 
              driver.dailySummaries[timeStamp] &&
              driver.dailySummaries[timeStamp].withdraw ?
          accumulator + parseInt(driver.dailySummaries[timeStamp].withdraw) :
          accumulator
      },0)
      driverTotals.doublesSource = dates.filter(date => {
        const timeStamp = convertDateToFirestoreTimestamp(props.firebase, date)
        if (!driver.dailySummaries) return false
        return driver.dailySummaries[timeStamp]
      }).map((date) => {
        const timeStamp = convertDateToFirestoreTimestamp(props.firebase, date)
        const formattedDate = `${date.getMonth() +1}-${date.getDate()}-${date.getFullYear()}`
        const doubles = driver.dailySummaries[timeStamp].doubles ? driver.dailySummaries[timeStamp].doubles : 0
        return `${formattedDate} - ${parseInt(doubles)}`
      })
      driverTotals.hoursSource = dates.filter(date => {
        const timeStamp = convertDateToFirestoreTimestamp(props.firebase, date)
        if (!driver.dailySummaries) return false
        return driver.dailySummaries[timeStamp]
      }).map((date) => {
        const timeStamp = convertDateToFirestoreTimestamp(props.firebase, date)
        const formattedDate = `${date.getMonth() +1}-${date.getDate()}-${date.getFullYear()}`
        const hours = driver.dailySummaries[timeStamp].hours ? driver.dailySummaries[timeStamp].hours : 0
        return `${formattedDate} - ${parseFloat(hours).toFixed(1)}`
      })
      driverTotals.bankSource = dates.filter(date => {
        const timeStamp = convertDateToFirestoreTimestamp(props.firebase, date)
        if (!driver.dailySummaries) return false
        return driver.dailySummaries[timeStamp]
      }).map((date) => {
        const timeStamp = convertDateToFirestoreTimestamp(props.firebase, date)
        const formattedDate = `${date.getMonth() +1}-${date.getDate()}-${date.getFullYear()}`
        const bank = driver.dailySummaries[timeStamp].bank ? driver.dailySummaries[timeStamp].bank : 0
        return `${formattedDate} - ${parseInt(bank)}`
      })
      driverTotals.withdrawSource = dates.filter(date => {
        const timeStamp = convertDateToFirestoreTimestamp(props.firebase, date)
        if (!driver.dailySummaries) return false
        return driver.dailySummaries[timeStamp]
      }).map((date) => {
        const timeStamp = convertDateToFirestoreTimestamp(props.firebase, date)
        const formattedDate = `${date.getMonth() +1}-${date.getDate()}-${date.getFullYear()}`
        const withdraw = driver.dailySummaries[timeStamp].withdraw ? driver.dailySummaries[timeStamp].withdraw : 0
        return `${formattedDate} - ${parseInt(withdraw)}`
      })
      result.push(driverTotals)
    })
    return sortTotalsByLastName(result)
  }

  const onStartDateChanged = (selectedDate) => {
    const jsDate = selectedDate.toDate()
    driversData.totalsByDriverForDateRange = calcTotalsByDriverForDateRange(driversData.driverRefs, jsDate, endDate)
    setStartDate(jsDate)
  }

  const onEndDateChanged = (selectedDate) => {
    const jsDate = selectedDate.toDate()
    driversData.totalsByDriverForDateRange = calcTotalsByDriverForDateRange(driversData.driverRefs,startDate, jsDate)
    setEndDate(jsDate)
  }

  if (!driversData) return <div></div>

  const getCellStyle = (value) => {
    return value > 0 ? {color: 'rgb(255,0,0)'} : {color: 'rgb(0,0,0)'}
  }

  return (
    <div>
      <div>
        <DatePicker
          label="Start Date (Inclusive)"
          value={startDate}
          onChange={onStartDateChanged}
          animateYearScrolling
        />
        <DatePicker
          label="End Date (Inclusive)"
          value={endDate}
          onChange={onEndDateChanged}
          animateYearScrolling
        />
        {convertDateToDateString(props.firebase, startDate)} - {convertDateToDateString(props.firebase, endDate)}
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Driver</TableCell>
              <TableCell >Doubles</TableCell>
              <TableCell >Hours</TableCell>
              <TableCell >Bank</TableCell>
              <TableCell >Withdraw</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {driversData.totalsByDriverForDateRange.map((driverTotals, index) => {
              return (
                  <TableRow key={index} >
                      <TableCell >{driverTotals.name}</TableCell>
                      <HtmlTooltip
                        placement="bottom-start"
                        title={
                          <React.Fragment>
                            <Typography color="inherit">Daily Totals</Typography>
                            {driverTotals.doublesSource.map(source => {
                              return ( <div>{source}</div> )
                            })}
                          </React.Fragment>
                        }
                      >
                        <TableCell >{driverTotals.doubles}</TableCell>
                      </HtmlTooltip>
                      <HtmlTooltip
                        placement="bottom-start"
                        title={
                          <React.Fragment>
                            <Typography color="inherit">Daily Totals</Typography>
                            {driverTotals.hoursSource.map(source => {
                              return ( <div>{source}</div> )
                            })}
                          </React.Fragment>
                        }
                      >
                        <TableCell >{driverTotals.hours}</TableCell>
                      </HtmlTooltip>
                      <HtmlTooltip
                        placement="bottom-start"
                        title={
                          <React.Fragment>
                            <Typography color="inherit">Daily Totals</Typography>
                            {driverTotals.bankSource.map(source => {
                              return ( <div>{source}</div> )
                            })}
                          </React.Fragment>
                        }
                      >
                        <TableCell style={getCellStyle(driverTotals.bank)}>{driverTotals.bank}</TableCell>
                      </HtmlTooltip>
                      <HtmlTooltip
                        placement="bottom-start"
                        title={
                          <React.Fragment>
                            <Typography color="inherit">Daily Totals</Typography>
                            {driverTotals.withdrawSource.map(source => {
                              return ( <div>{source}</div> )
                            })}
                          </React.Fragment>
                        }
                      >
                        <TableCell style={getCellStyle(driverTotals.withdraw)}>{driverTotals.withdraw}</TableCell>
                      </HtmlTooltip>
                  </TableRow>
              )}
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>

  )

}