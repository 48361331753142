import React from 'react'
import { Button } from '@material-ui/core'

export default class OrdersTableColumns {

  constructor(customerRefs, cities, subdivisions, materials, purposes, drivers, showPO, showOrderReference, showDeliveryInformation) {

    const customerNameKeyValues = {}
    customerRefs.sort((customerRefA, customerRefB) => {
      return customerRefA.data().name < customerRefB.data().name ? -1 : 1
    }).forEach(customerRef => {
      customerNameKeyValues[customerRef.data().name] = customerRef.data().name
    })
    const cityNameKeyValues = {}
    cities.sort((cityA, cityB) => {
      return cityA.name < cityB.name ? -1 : 1
    }).forEach(city => {
      cityNameKeyValues[city.name] = city.name
    })
    const areaNameKeyValues = {}
    cities.sort((cityA, cityB) => {
      return cityA.area < cityB.area ? -1 : 1
    }).forEach(city => {
      areaNameKeyValues[city.area.trim()] = city.area.trim()
    })
    const subdivisionNameKeyValues = {}
    subdivisions.sort((a, b) => {
      return a < b ? -1 : 1
    }).forEach(subdivision => {
      subdivisionNameKeyValues[subdivision] = subdivision
    })
    const materialNameKeyValues = {}
    materials.sort((materialA, materialB) => {
      return materialA.name < materialB.name ? -1 : 1
    }).forEach(material => {
      materialNameKeyValues[material.name] = material.name
    })
    const purposeNameKeyValues = {}
    purposeNameKeyValues[""] = ""
    purposes.sort((purposeA, purposeB) => {
      return purposeA.name < purposeB.name ? -1 : 1
    }).forEach(purpose => {
      purposeNameKeyValues[purpose.name] = purpose.name
    })
    const truckNameKeyValues = {}
    drivers.sort((driverA, driverB) => {
      return driverA.truck < driverB.truck ? -1 : 1
    }).forEach(driver => {
      truckNameKeyValues[driver.truck] = driver.truck ? driver.truck : ''
    })
    const driverNameKeyValues = {}
    drivers.sort((driverA, driverB) => {
      return driverA.name < driverB.name ? -1 : 1
    }).forEach(driver => {
      driverNameKeyValues[driver.name] = driver.name
    })

    const columnDefs = [
      { 
        field: 'ref', 
        hidden: true 
      },
      { 
        title: 'Anticipated Delivery Date', 
        field: 'anticipatedDeliveryDate',
        type: 'date',
        customFilterAndSearch: (term, rowData) => {
          return this.testDateInCustomFilterAndSearch(term, rowData.anticipatedDeliveryDate)
        }
      },
      { 
        title: 'Requested Delivery Date', 
        field: 'requestedDeliveryDate',
        type: 'date',
        customFilterAndSearch: (term, rowData) => {
          return this.testDateInCustomFilterAndSearch(term, rowData.requestedDeliveryDate)
        },
        cellStyle: (e, rowData) => {
          if (rowData.requestedDeliveryDate) {
            return { color: "red", fontWeight: "bold" };
          }
        },
      },
      { 
        title: 'Actual Delivery Date', 
        field: 'actualDeliveryDate',
        type: 'date',
        customFilterAndSearch: (term, rowData) => {
          return this.testDateInCustomFilterAndSearch(term, rowData.actualDeliveryDate)
        },
        render: rowData => {
          if (rowData.actualDeliveryDate) {
            return <Button variant="contained" color="primary" onClick={() => showDeliveryInformation(rowData)}>{rowData.actualDeliveryDate.toLocaleDateString()}</Button>
          }
          return ''
        } 
      },
      { 
        title: 'Area', 
        field: 'area',
        lookup: areaNameKeyValues
      },
      { 
        title: 'Bill To Address', 
        field: 'billToAddress',
        customSort: (a, b) => {
          const streetNameA = a.billToAddress.substr(a.billToAddress.indexOf(' ')+1)
          const streetNameB = b.billToAddress.substr(b.billToAddress.indexOf(' ')+1)
          if (streetNameA !== streetNameB) {
            return streetNameA < streetNameB ? -1 : 1
          }
          const streetNumberA = a.billToAddress.substr(0,a.billToAddress.indexOf(' '))
          const streetNumberB = b.billToAddress.substr(0,b.billToAddress.indexOf(' '))
          if (streetNumberA !== streetNumberB) {
            return streetNumberA < streetNumberB ? -1 : 1
          }
          return 0      
        }
      },
      { 
        title: 'Cancelled', 
        field: 'cancelled',
        type: 'boolean' 
      },
      { 
        title: 'Cancelled Date', 
        field: 'cancelledDate',
        type: 'date',
        customFilterAndSearch: (term, rowData) => {
          return this.testDateInCustomFilterAndSearch(term, rowData.cancelledDate)
        }
      },
      { 
        title: 'City', 
        field: 'city',
        lookup: cityNameKeyValues
      },
      { 
        title: 'Contact', 
        field: 'contact' 
      },
      { 
        title: 'Contact Email or Phone', 
        field: 'contactEmail' 
      },
      { 
        title: 'Customer', 
        field: 'customer',
        customFilterAndSearch: (term, rowData) => {
          if (term instanceof Array) {
            return term.length === 0 ? true : term.includes(rowData.customer)
          }
          if (typeof term === 'string') {
            return rowData.customer.toUpperCase().includes(term.toUpperCase())
          }
          return false
        }
      },
      { 
        title: 'Delivered', 
        field: 'delivered',
        type: 'boolean' 
      },
      { 
        title: 'Delivery Address', 
        field: 'deliveryAddress',
        customSort: (a, b) => {
          const streetNameA = a.deliveryAddress.substr(a.deliveryAddress.indexOf(' ')+1)
          const streetNameB = b.deliveryAddress.substr(b.deliveryAddress.indexOf(' ')+1)
          if (streetNameA !== streetNameB) {
            return streetNameA < streetNameB ? -1 : 1
          }
          const streetNumberA = a.deliveryAddress.substr(0,a.deliveryAddress.indexOf(' '))
          const streetNumberB = b.deliveryAddress.substr(0,b.deliveryAddress.indexOf(' '))
          if (streetNumberA !== streetNumberB) {
            return streetNumberA < streetNumberB ? -1 : 1
          }
          return 0      
        }
      },
      { 
        title: 'Delivery Location', 
        field: 'deliveryLocation' 
      },
      { 
        title: 'Delivery Notes', 
        field: 'deliveryNotes' 
      },
      { 
        title: 'Dispatched', 
        field: 'dispatched',
        type: 'boolean' 
      },
      { 
        title: 'Dispatched Date', 
        field: 'dispatchedDate',
        type: 'date',
        customFilterAndSearch: (term, rowData) => {
          return this.testDateInCustomFilterAndSearch(term, rowData.dispatchedDate)
        }
      },
      { 
        title: 'Preloaded', 
        field: 'preloaded',
        type: 'boolean' 
      },
      { 
        title: 'Preloaded Date', 
        field: 'preloadedDate',
        type: 'date',
        customFilterAndSearch: (term, rowData) => {
          return this.testDateInCustomFilterAndSearch(term, rowData.preloadedDate)
        }
      },
      { 
        title: 'Seq', 
        field: 'dispatchSequence' 
      },
      { 
        title: 'Heavy', 
        field: 'heavy',
        type: 'boolean'
      },
      { 
        title: 'Held', 
        field: 'held',
        type: 'boolean' 
      },
      { 
      title: 'Held Date', 
        field: 'heldDate',
        type: 'date',
        customFilterAndSearch: (term, rowData) => {
          return this.testDateInCustomFilterAndSearch(term, rowData.heldDate)
        }
      },
      { 
        title: 'Invoice', 
        field: 'invoice' 
      },
      { 
        title: 'Loads', 
        field: 'loads' 
      },
      { 
        title: 'Location', 
        field: 'location' 
      },
      { 
        title: 'Map', 
        field: 'map' 
      },
      { 
        title: 'Material', 
        field: 'material',
        lookup: materialNameKeyValues
      },
      { 
        title: 'Order Notes', 
        field: 'notes' 
      },
      { 
        title: 'Order Date',
        field: 'orderDate',
        type: 'date',
        customFilterAndSearch: (term, rowData) => {
          return this.testDateInCustomFilterAndSearch(term, rowData.orderDate)
        }

      },
      { 
        title: 'Order Reference', 
        field: 'orderReference',
        render: rowData => {
          if (rowData.orderReference) {
            return <Button variant="contained" color="primary" onClick={() => showOrderReference(rowData.orderReference)}>{rowData.orderReference}</Button>
          }
          return ''
        } 
      },
      { 
        title: 'Payroll Loads', 
        field: 'payrollLoads'
      },
      { 
        title: 'Pit', 
        field: 'pit'
      },
      { 
        title: 'Priority', 
        field: 'priority',
        type: 'boolean'
      },
      { 
        title: 'PO', 
        field: 'po',
        render: rowData => {
          if (rowData.po) {
            return <Button variant="contained" color="primary" onClick={() => showPO(rowData.po, rowData.customer)}>{rowData.po}</Button>  
          }
          return ''
        }

      },
      { 
        title: 'Price', 
        field: 'price' 
      },
      { 
        title: 'Purpose', 
        field: 'purpose',
        lookup: purposeNameKeyValues
      },
      { 
        title: 'Ready', 
        field: 'ready',
        type: 'boolean' 
      },
      { 
        title: 'Ready Date', 
        field: 'readyDate',
        type: 'date',
        customFilterAndSearch: (term, rowData) => {
          return this.testDateInCustomFilterAndSearch(term, rowData.readyDate)
        }
      },
      { 
        title: 'Ready To Invoice', 
        field: 'readyToInvoice',
        type: 'boolean' 
      },
      { 
        title: 'Ready To Invoice Date', 
        field: 'readyToInvoiceDate',
        type: 'date',
        customFilterAndSearch: (term, rowData) => {
          return this.testDateInCustomFilterAndSearch(term, rowData.readyToInvoiceDate)
        }
      },
      { 
        title: 'Status',
        field: 'status'
      },
      { 
        title: 'Subdivision', 
        field: 'subdivision',
        lookup: subdivisionNameKeyValues
      },
      { 
        title: 'Ticket', 
        field: 'ticket'
      },
      { 
        title: 'Truck', 
        field: 'truck',
        lookup: truckNameKeyValues
      },
      { 
        title: 'Driver', 
        field: 'driver',
        lookup: driverNameKeyValues
      }
    ]

    this.columnMap = {}
    columnDefs.forEach(columnDef => {
      this.columnMap[columnDef.field] = columnDef
    })

  }

  makeColumnList = (desiredColumns) => {
    return desiredColumns.map(desiredColumn => {
      return this.columnMap[desiredColumn]
    })
  }

  testDateInCustomFilterAndSearch(term, dateFromRow) {
    if(!(term instanceof Date)) {
      return false
    }
    if (!dateFromRow) {
      return false
    }
    const filterDate = term
    const rowDate = dateFromRow
    filterDate.setHours(0,0,0,0)
    rowDate.setHours(0,0,0,0)
    return filterDate.getTime() === rowDate.getTime()
  }

}