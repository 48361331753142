import React, { useState, useEffect, useRef } from 'react'
import './App.css'
import clsx from 'clsx'
import LoginPage from './authentication/LoginPage'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import PropTypes from 'prop-types'
import NewOrder from './new_order/NewOrder'
import BatchDispatch from './orders/BatchDispatch'
import UploadInvoicing from './orders/UploadInvoicing'
import UploadNewSubdivision from './orders/UploadNewSubdivision'
import FutureOrders from './orders/FutureOrders'
import ReadyOrders from './orders/ReadyOrders'
import DispatchedOrders from './orders/DispatchedOrders'
import DeliveredOrders from './orders/DeliveredOrders'
import ReadyToInvoiceOrders from './orders/ReadyToInvoiceOrders'
import CancelledOrders from './orders/CancelledOrders'
import HeldOrders from './orders/HeldOrders'
import AllOrders from './orders/AllOrders'
import RecentOrders from './orders/RecentOrders'
import DriverLoads from './reporting/DriverLoads'
import NavItemLink from './navigation/NavItemLink'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { CssBaseline, SvgIcon } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import logo from './EaseUrLoadLogoDesign.png'
import companyLogo from './PervasiveConceptsLogomarkOnly.png'
import companyText from './PervasiveConceptsTextOnly.png'
import ReportContainer from './reporting/ReportContainer'
import MetaDataContainer from './metadata/MetadataContainer'

import SvgAllOrders from './icons/AllOrders'
import SvgCancelled from './icons/Cancelled'
import SvgDelivered from './icons/Delivered'
import SvgDispatched from './icons/Dispatched'
import SvgFuture from './icons/Future'
import SvgHeld from './icons/Held'
import SvgLoadCount from './icons/LoadCount'
import SvgMetaData from './icons/MetaData'
import SvgReadyToInvoice from './icons/ReadyToInvoice'
import SvgRecentOrders from './icons/RecentOrders'
import SvgReporting from './icons/Reporting'
import SvgReadyOrders from './icons/ReadyOrders'

require('@firebase/firestore')
require('@firebase/auth')

App.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  firebaseauth: PropTypes.object.isRequired
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    fill: 'white'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 4,
  },
  titleLogo: {
    width: '200px',
    height: '50px',
  },
  byline: {
    'padding-left': '15px'
  },
  companyLogo: {
    width: '30px',
    height: '30px',
    'margin-left': '15px'
  },
  companyText: {
    width: '200px',
    height: '20px',
    'padding-left': '40px'
  },
  titleContainer: {
    width: '70%',
  },
}));

function App(props) {

  const classes = useStyles();
  const theme = useTheme();

  const {location, firebase} = props

  const [addingNewOrder, setAddingNewOrder] = useState(false)
  const [addingFutureOrder, setAddingFutureOrder] = useState(false)
  const [uploadInvoicing, setUploadInvoicing] = useState(false)
  const [uploadNewSubdivision, setUploadNewSubdivision] = useState(false)
  const [batchDispatch, setBatchDispatch] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const [rowColors, setRowColors] = useState(null)
  const [customerRefs, setCustomerRefs] = useState(null)
  const [materials, setMaterials] = useState(null)
  const [purpose, setPurpose] = useState(null)
  const [pricing, setPricing] = useState(null)
  const [cities, setCities] = useState(null)
  const [subdivisions, setSubdivisions] = useState(null)
  const [drivers, setDrivers] = useState(null)
  const [pits, setPits] = useState(null)
  const [open, setOpen] = useState(false)

  const customersQuerySnapshot = useRef(undefined)
  const driversQuerySnapshot = useRef(undefined)
  const subdivisionQuerySnapshot = useRef(undefined)
  const navitems2 = useRef(undefined)

  const db = props.db
  const firebaseauth = props.firebaseauth

  useEffect(() => {

    let cities = []
    let subdivisions = []
    let materials = []
    let purpose = []
    let pricing = []
    let drivers = []
    let pits = []
    const rowColorMap = new Map()
    let customerRefs2 = []
    const TO_PREFIX = ''

    const postAuthenticationProcesses = () => {
      const requestArray = []
      requestArray.push(db.collection('colors').get())
      requestArray.push(db.collection('customers').get())
      requestArray.push(db.collection('materials').get())
      requestArray.push(db.collection('purpose').get())
      requestArray.push(db.collection('pricing').get())
      requestArray.push(db.collection('cities').get())
      requestArray.push(db.collection('subdivisions').get())
      requestArray.push(db.collection('drivers').get())
      requestArray.push(db.collection('pits').get())

      // requestArray.push(db.collection('orders').get())
      Promise.all(requestArray).then(results => {
        let querySnapshot = results[0]
        querySnapshot.forEach(doc => {
          rowColorMap.set(doc.data().purpose, doc.data().color)
        })


        customersQuerySnapshot.current = results[1]
        // this has to be done, the onSnapshot event fires after
        // rendering for some components
        setCustomerRefs(customersQuerySnapshot.current.docs)
        customerRefs2 = customersQuerySnapshot.current.docs
        customersQuerySnapshot.current.query.onSnapshot(querySnapshot => {
          setCustomerRefs(querySnapshot.docs)
          customerRefs2 = querySnapshot.docs
        })

        
        querySnapshot = results[2]
        materials = []
        querySnapshot.forEach(doc => {
          materials.push(doc.data())
        });
        querySnapshot = results[3]
        purpose = []
        querySnapshot.forEach(doc => {
          purpose.push(doc.data())
        });
        querySnapshot = results[4]
        pricing = []
        querySnapshot.forEach(doc => {
          pricing.push(doc.data())
        });      
        querySnapshot = results[5]
        cities = []
        querySnapshot.forEach(doc => {
          cities.push(doc.data())
        });
        subdivisionQuerySnapshot.current = results[6]
        subdivisionQuerySnapshot.current.query.onSnapshot(querySnapshot => {
          subdivisions = []
          subdivisions.push('')
          querySnapshot.forEach(doc => {
            subdivisions.push(doc.data().name)
          })
          setSubdivisions(subdivisions)
        })

        driversQuerySnapshot.current = results[7]
        // this has to be done, the onSnapshot event fires after
        // rendering for some components
        drivers = []
        driversQuerySnapshot.current.forEach(doc => {
          drivers.push(doc.data())
        });
        driversQuerySnapshot.current.query.onSnapshot(querySnapshot => {
          drivers = []
          querySnapshot.forEach(doc => {
            drivers.push(doc.data())
          });
          setDrivers(drivers)
        })

        querySnapshot = results[8]
        pits = []
        querySnapshot.forEach(doc => {
          pits.push(doc.data())
        });
        setRowColors(rowColorMap)
        setMaterials(materials) 
        setPurpose(purpose)
        setPricing(pricing)
        setCities(cities)
        setDrivers(drivers)
        setPits(pits)
        setAuthenticated(true)
      })
      .catch(err => {
        alert(err)
      })
    }

    const getUserDefinitionInEaseUrLoad = async (user) => {
      const query = db.collection('users').where('email', '==', user.email)
      const querySnapshot = await query.get()
      if (querySnapshot.empty) return undefined
      return querySnapshot.docs[0]
    }

    const validateUserIsAdmin = async (user) => {
      const internalUser = await getUserDefinitionInEaseUrLoad(user)
      if (!internalUser) return false
      return internalUser.data().roles.includes('admin')
    }
  
    const authenticationChange = async (user) => {
      if (!user) {
        setAuthenticated(false)
        return
      }      
      console.log(user.email)
      const isAdmin = await validateUserIsAdmin(user)
      if (!isAdmin) {
        alert("User is not admin")
        setAuthenticated(false)
        return
      }
      postAuthenticationProcesses()
    }
  
    firebaseauth.onAuthStateChanged(authenticationChange)

    navitems2.current = [
      {
        label: 'Ready',
        to: `${TO_PREFIX}/ready`,
        exact: false,
        icon: <SvgIcon component={SvgReadyOrders} viewBox="0 0 375 375" fontSize='large' />,
        render: () => 
          <ReadyOrders firebase={firebase} db={db} 
            customerRefs={customerRefs2} 
            materials={materials}
            purposes={purpose}
            cities={cities}
            subdivisions={subdivisions}
            pricing={pricing}
            drivers={drivers}
            rowColorMap={rowColorMap}/>
      },
      {
        label: 'Dispatched',
        to: `${TO_PREFIX}/dispatched`,
        exact: false,
        icon: <SvgIcon component={SvgDispatched} viewBox="0 0 1440 1440" fontSize='large'/>,
        render: () => 
          <DispatchedOrders firebase={firebase} db={db} 
            customerRefs={customerRefs2} 
            materials={materials}
            purposes={purpose}
            cities={cities}
            subdivisions={subdivisions}
            pricing={pricing}
            drivers={drivers}
            rowColorMap={rowColorMap}/>
      }, {
        label: 'Load Count',
        to: `${TO_PREFIX}/loadCount`,
        exact: true,
        icon: <SvgIcon component={SvgLoadCount} viewBox="0 0 1440 1440" fontSize='large'/>,
        render: () => 
          <DriverLoads firebase={firebase} db={db} 
          customerRefs={customerRefs2} 
          materials={materials}
          purposes={purpose}
          cities={cities}
          subdivisions={subdivisions}
          pricing={pricing}
          drivers={drivers}
          rowColorMap={rowColorMap}/>
      }, {
        label: 'Delivered',
        to: `${TO_PREFIX}/delivered`,
        exact: false,
        icon: <SvgIcon component={SvgDelivered} viewBox="0 0 1440 1440" fontSize='large'/>,
        render: () => 
          <DeliveredOrders firebase={firebase} db={db} 
            customerRefs={customerRefs2} 
            materials={materials}
            purposes={purpose}
            cities={cities}
            subdivisions={subdivisions}
            pricing={pricing}
            drivers={drivers}
            rowColorMap={rowColorMap}/>
      }, {
        label: 'Ready To Invoice',
        to: `${TO_PREFIX}/readytoinvoice`,
        exact: false,
        icon: <SvgIcon component={SvgReadyToInvoice} viewBox="0 0 1440 1440" fontSize='large'/>,
        render: () => 
          <ReadyToInvoiceOrders firebase={firebase} db={db} 
            customerRefs={customerRefs2} 
            materials={materials}
            purposes={purpose}
            cities={cities}
            subdivisions={subdivisions}
            pricing={pricing}
            drivers={drivers}
            rowColorMap={rowColorMap}/> 
      }, {
        label: 'Held',
        to: `${TO_PREFIX}/held`,
        exact: false,
        icon: <SvgIcon component={SvgHeld} viewBox="0 0 1440 1440" fontSize='large'/>,
        render: () => 
          <HeldOrders firebase={firebase} db={db} 
            customerRefs={customerRefs2} 
            materials={materials}
            purposes={purpose}
            cities={cities}
            subdivisions={subdivisions}
            pricing={pricing}
            drivers={drivers}
            rowColorMap={rowColorMap}/>
      }, {
        label: 'Canceled',
        to: `${TO_PREFIX}/canceled`,
        exact: false,
        icon: <SvgIcon component={SvgCancelled} viewBox="0 0 1440 1440" fontSize='large'/>,
        render: () => 
          <CancelledOrders firebase={firebase} db={db} 
            customerRefs={customerRefs2} 
            materials={materials}
            purposes={purpose}
            cities={cities}
            subdivisions={subdivisions}
            pricing={pricing}
            drivers={drivers}
            rowColorMap={rowColorMap}/>
      }, {
        label: 'Recent Orders',
        to: `${TO_PREFIX}/recentorders`,
        exact: false,
        icon: <SvgIcon component={SvgRecentOrders} viewBox="0 0 1440 1440" fontSize='large'/>,
        render: () => 
          <RecentOrders firebase={firebase} db={db} 
            customerRefs={customerRefs2} 
            materials={materials}
            purposes={purpose}
            cities={cities}
            subdivisions={subdivisions}
            pricing={pricing}
            drivers={drivers}
            rowColorMap={rowColorMap}/>
      }, {
        label: 'Future',
        to: `${TO_PREFIX}/future`,
        exact: true,
        icon: <SvgIcon component={SvgFuture} viewBox="0 0 1440 1440" fontSize='large'/>,
        render: () => 
          <FutureOrders firebase={firebase} db={db} 
            customerRefs={customerRefs2} 
            materials={materials}
            purposes={purpose}
            cities={cities}
            subdivisions={subdivisions}
            pricing={pricing}
            drivers={drivers}
            rowColorMap={rowColorMap}/>
      }, {
        label: 'Reporting',
        to: `${TO_PREFIX}/reporting`,
        exact: false,
        icon: <SvgIcon component={SvgReporting} viewBox="0 0 1440 1440" fontSize='large'/>,
        render: () => 
          <ReportContainer firebase={firebase} db={db} />
      }, {
        label: 'All Orders',
        to: `${TO_PREFIX}/allorders`,
        exact: false,
        icon: <SvgIcon component={SvgAllOrders} viewBox="0 0 1440 1440" fontSize='large'/>,
        render: () => 
          <AllOrders firebase={firebase} db={db} 
            customerRefs={customerRefs2} 
            materials={materials}
            purposes={purpose}
            cities={cities}
            subdivisions={subdivisions}
            pricing={pricing}
            drivers={drivers}
            rowColorMap={rowColorMap}/>
      }, {
        label: 'Metadata',
        to: `${TO_PREFIX}/metadata`,
        exact: false,
        icon: <SvgIcon component={SvgMetaData} viewBox="0 0 1440 1440" fontSize='large'/>,
        render: () => 
          <MetaDataContainer firebase={firebase} db={db} 
            customerRefs={customerRefs2} 
            materials={materials}
            purposes={purpose}
            cities={cities}
            subdivisions={subdivisions}
            pricingModels={pricing}
            drivers={drivers}
            rowColorMap={rowColorMap}/>
      }
    ]

  }, [firebase, db, firebaseauth, setAuthenticated]);

  const logout = () => {
    firebaseauth.signOut().then(function() {
      setAuthenticated(false)
    }, function(error) {
      alert(error)
    });
  }

  const showAddOrder = () => {
    setAddingNewOrder(true)
  }

  // const showAddFutureOrder = () => {
  //   setAddingFutureOrder(true)
  // }

  const showUploadInvoicing = () => {
    setUploadInvoicing(true)
  }

  const showUploadNewSubdivision = () => {
    setUploadNewSubdivision(true)
  }

  const onFinishedUploadInvoicing = () => {
    setUploadInvoicing(false)
  }

  const onFinishedUploadNewSubdivision = () => {
    setUploadNewSubdivision(false)
  }

  const onFinishedAddingNewOrder = () => {
    setAddingNewOrder(false)
  }

  const onFinishedAddingFutureOrder = () => {
    setAddingFutureOrder(false)
  }

  const showBatchDispatch = () => {
    setBatchDispatch(true)
  }

  const onFinishedBatchDispatch = () => {
    setBatchDispatch(false)
  }


  const handleDrawerOpen = () => {
    setOpen(true);
  }

  const handleDrawerClose = () => {
    setOpen(false);
  }

  if (authenticated === false) {
    return (<LoginPage firebaseauth={firebaseauth} show={!authenticated}/>)
  }
  if (!customerRefs) {
    return
  }
  if (addingNewOrder === true) {
    return (<NewOrder 
              firebase={firebase}
              db={db}
              onClose={onFinishedAddingNewOrder} 
              customerRefs={customerRefs}
              materials={materials}
              purposes={purpose}
              cities={cities}
              subdivisions={subdivisions}
              pricing={pricing}
              open={addingNewOrder}
              rowColorMap={rowColors}
              drivers={drivers}
            />)
  }  
  if (uploadInvoicing === true) {
    return (<UploadInvoicing 
      firebase={firebase}
      db={db}
      onClose={onFinishedUploadInvoicing} 
      customerRefs={customerRefs}
      materials={materials}
      purposes={purpose}
      cities={cities}
      subdivisions={subdivisions}
      pricing={pricing}
      drivers={drivers}
      open={uploadInvoicing}
    />)
  }  
  if (uploadNewSubdivision === true) {
    return (<UploadNewSubdivision
      firebase={firebase}
      db={db}
      onClose={onFinishedUploadNewSubdivision} 
      customerRefs={customerRefs}
      materials={materials}
      purposes={purpose}
      cities={cities}
      subdivisions={subdivisions}
      pricing={pricing}
      drivers={drivers}
      open={uploadNewSubdivision}
    />)
  }  
  if (addingFutureOrder === true) {
    return (<NewOrder 
              firebase={firebase}
              db={db}
              onClose={onFinishedAddingFutureOrder} 
              customerRefs={customerRefs}
              materials={materials}
              purposes={purpose}
              cities={cities}
              subdivisions={subdivisions}
              pricing={pricing}
              open={addingFutureOrder}
              rowColorMap={rowColors}
              isFuture={true}
            />)
  }  
  if (batchDispatch === true) {
    return (<BatchDispatch 
      firebase={firebase}
      db={db}
      onClose={onFinishedBatchDispatch} 
      customerRefs={customerRefs}
      materials={materials}
      purposes={purpose}
      cities={cities}
      subdivisions={subdivisions}
      pricing={pricing}
      drivers={drivers}
      open={batchDispatch}
      rowColorMap={rowColors}
      pits={pits}
    />)
}  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton 
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <img src={companyLogo} alt="Pervasive Concepts" className={classes.companyLogo}/>
          </IconButton>
          <div className={classes.titleContainer}>
            <Tooltip 
              title={
                <React.Fragment>
                  <h1>EaseUrLoad Info</h1>
                  <h2>The EaseUrLoad UI uses <em>React</em> with <em>Material Design for React</em> for styling.</h2>
                  <h2>Server side processing is performed on <em>Google Cloud Platform</em> using <em>Firebase</em></h2>
                </React.Fragment>
              }>
              <img src={logo} alt="EaseUrLoad" className={classes.titleLogo}/>
            </Tooltip>
          </div>
          <Typography variant="h3">{props.env}</Typography>
          <Button color="inherit" onClick={showBatchDispatch} >Dispatch</Button>
          {/* <Button color="inherit" onClick={showAddFutureOrder} >New Future Order</Button> */}
          {/* <Button color="inherit" onClick={showAddCustomer} >New Customer</Button> */}
          <Button color="inherit" onClick={showAddOrder} >New Order</Button>
          <Button color="inherit" onClick={showUploadInvoicing} >Upload Invoicing</Button>
          <Button color="inherit" onClick={showUploadNewSubdivision} >Upload New Subdivision</Button>
          <Button color="inherit" onClick={logout} >Logout</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}      
        >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {navitems2.current.map((item) => (<NavItemLink {...item} key={item.to} />))}
          {/* {navitems.map((item) => (<NavItemLink {...item} key={item.to} />))} */}
        </List>
        <div>
          <Typography className={classes.byline}>Powered by</Typography>
          <img src={companyLogo} alt="Pervasive Concepts" className={classes.companyLogo}/>
          <img src={companyText} alt="Pervasive Concepts" className={classes.companyText}/>
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch key={location.pathname}>
          {navitems2.current.map((item) => (<Route path={item.to} render={item.render} key={item.to}/>))}
        </Switch>
      </main>
    </div>
  )

}

export default withRouter(App);