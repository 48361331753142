import { DriverUtilitiesError } from './errorMessages'


export default class DriverUtilities {

  static performDefaultSortForDrivers = (driverRefs) => {
    return driverRefs.sort((subRefA, subRefB) => {
      return subRefA.data().name < subRefB.data().name ? -1 : 1
    })  
  }
  
  static performDefaultSortForDriverNames = (driverNames) => {
    return driverNames.sort((nameA, nameB) => {
      const lastNameA = nameA.substr(nameA.indexOf(' ')+1)
      const lastNameB = nameB.substr(nameB.indexOf(' ')+1)
      return lastNameA.trim() < lastNameB.trim() ? -1 : 1
    })  
  }
  
  static performFirstNameSortForDriverNames = (driverNames) => {
    return driverNames.sort((nameA, nameB) => {
      const firstNameA = nameA.substr(0, nameA.indexOf(' '))
      const firstNameB = nameB.substr(0, nameB.indexOf(' '))
      return firstNameA.trim() < firstNameB.trim() ? -1 : 1
    })  
  }

  static sortDriverObjectsByTruck = (drivers) => {
    return drivers.sort((driverA, driverB) => {
      const truckA = driverA.truck ? driverA.truck.trim() : 0
      const truckB = driverB.truck ? driverB.truck.trim() : 0
      return truckA < truckB ? -1 : 1
    })  
  }
  
  static limitDriversToActiveDrivers = (drivers) => {
    return drivers.filter((driver) => {
      if (!driver.truck) return false
      return driver.currentlyDriving
    })
  }

  static limitDriverRefsToActiveDrivers = (driverRefs) => {
    return driverRefs.filter((driverRef) => {
      if (!driverRef.data().truck) return false
      return driverRef.data().currentlyDriving
    })
  }

  static convertSnapshotDocsToListWithRefId = (driversSnapshotDocs) => {
    return driversSnapshotDocs.map(driverRef => {
      return {ref: driverRef.id, ...driverRef.data()}
    })
  }

  static fetchAllForTable = (driversSnapshotDocs) => {
    return DriverUtilities.convertSnapshotDocsToListWithRefId(driversSnapshotDocs)
  }

  static findDriverRef(driverName, driversSnapshotDocs) {
    return driversSnapshotDocs.filter(driverRef => {
      return driverRef.data().name === driverName
    })[0]
  }

  static async recordDaySummaryForDriver(driverToUpdate, summaryDate, doubles, hours, bank, withdraw, driversSnapshotDocs) {
    const driver = {...driverToUpdate}
    if (!driver.dailySummaries) {
      driver.dailySummaries = {}
    }
    const driverSummariesForDate = {
      doubles: parseInt(doubles),
      hours: parseFloat(hours),
      bank: parseInt(bank),
      withdraw: parseInt(withdraw)
    }
    driver.dailySummaries[summaryDate] = driverSummariesForDate
    const driverRef = this.findDriverRef(driverToUpdate.name, driversSnapshotDocs)
    driverRef.ref.update(driver)
  }

  static update = async (db, id, data) => {
    if (data.ref) {
      throw new Error(DriverUtilitiesError.removeRefForUpdate)
    }
    await db.collection('drivers').doc(id).set(data).catch(error => {
      console.error("Error updating document: ", error);
    });
  }

  static add = async (db, data) => {
    await db.collection('drivers').add(data)
  }

}