import React, {useState} from 'react'
import { Tabs, Tab } from '@material-ui/core';
import DriverLoadsForPayroll from './DriverLoadsForPayroll';
import _ from 'lodash'

export default function ReportContainer(props) {

  const reports = [
    {
      name: 'Load Log for Payroll',
      html: (<DriverLoadsForPayroll firebase={props.firebase} db={props.db}/>)
    }
  ]
  const reportsMap = _.keyBy(reports, (o) => { return o.name })

  const value = reports[0].name
  const [ html, setHtml ] = useState(reports[0].html)

  const handleTabChange = (value) => {
    setHtml(getReportForValue(value))
  }

  const getReportForValue = (value) => {
    return reportsMap[value].html
  }

  if (!value) return <div></div>

  return (
    <div>
      <Tabs value={value} 
        onChange={handleTabChange}
        variant="scrollable" >
      {reports.map(report => (
        <Tab key={report.name} label={report.name} value={report.name}/>
      ))}
      </Tabs>
      {html}
    </div>
  ) 

}