import { TrucksUtilitiesError } from './errorMessages'
import { convertFirestoreTimestampToDate } from './FirestoreUtilities'

export const performDefaultSortForTrucksRefs = (trucksRefs) => {
  return trucksRefs.sort((truckRefA, truckRefB) => {
    return truckRefA.data().truck < truckRefB.data().truck ? -1 : 1
  })  
}

export default class TrucksUtilities {

  constructor(firebase, db, trucksRefs) {
    this.firebase = firebase
    this.db = db
    this.trucksRefs = trucksRefs
  }

  getDateProperties = () => {
    return [
      'lastInspectionDate'
    ]
  }

  fetchRefsForAll = () => {
    return this.trucksRefs
  }

  fetchAllForTable = () => {
    const result= this.trucksRefs.map(truckRef => {
      return { ref: truckRef.id, ...truckRef.data() }
    })
    return result
  }

  add = async (data) => {
    await this.db.collection('trucks').add(data)
  }

  convertForTruckTable = (truckRef) => {
    const dateProperties = this.getDateProperties()
    const convertedTruck = truckRef.data()
    dateProperties.forEach(property => {
      const tempTimestamp = convertedTruck[property]
      convertedTruck[property] = convertFirestoreTimestampToDate(this.firebase, tempTimestamp)
    })
    return {ref: truckRef.id, ...convertedTruck}
  }

}