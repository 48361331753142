import { PitsUtilitiesError } from './errorMessages'

export const performDefaultSortForPitsRefs = (pitRefs) => {
  return pitRefs.sort((pitRefA, pitRefB) => {
    return pitRefA.data().name < pitRefB.data().name ? -1 : 1
  })  
}

export default class PitsUtilities {

  constructor(firebase, db, pitsRefs) {
    this.firebase = firebase
    this.db = db
    this.pitsRefs = pitsRefs
  }

  fetchRefsForAll = () => {
    return this.pitsRefs
  }

  fetchAllForTable = () => {
    const result= this.pitsRefs.map(pitRef => {
      return { ref: pitRef.id, ...pitRef.data() }
    })
    return result
  }

  add = async (data) => {
    await this.db.collection('pits').add(data)
  }

  update = async (id, data) => {
    if (data.ref) {
      throw new Error(PitsUtilitiesError.removeRefForUpdate)
    }
    await this.db.collection('pits').doc(id).set(data).catch(error => {
      console.error("Error updating document: ", error);
    });
  }

}