import { SubdivisionUtilitiesError } from './errorMessages'

export const performDefaultSortForSubdivisionRefs = (subdivisionRefs) => {
  return subdivisionRefs.sort((subRefA, subRefB) => {
    return subRefA.data().name < subRefB.data().name ? -1 : 1
  })  
}

export default class SubdivisionUtilities {

  constructor(firebase, db, subdivisionRefs) {
    this.firebase = firebase
    this.db = db
    this.subdivisionRefs = subdivisionRefs
  }

  fetchRefsForAll = () => {
    return this.subdivisionRefs
  }

  fetchAllForTable = () => {
    const result= this.subdivisionRefs.map(subdivisionRef => {
      return { ref: subdivisionRef.id, ...subdivisionRef.data() }
    })
    return result
  }

  add = async (data) => {
    await this.db.collection('subdivisions').add(data)
  }

  update = async (id, data) => {
    if (data.ref) {
      throw new Error(SubdivisionUtilitiesError.removeRefForUpdate)
    }
    await this.db.collection('subdivisions').doc(id).set(data).catch(error => {
      console.error("Error updating document: ", error);
    });
  }

}