import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import OrderInstructions from './OrderInstructions'
import _ from 'lodash'


DialogForDispatchWithInstructions.propTypes = {
  firebase: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  rowColorMap: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  orderUtilities: PropTypes.object.isRequired,
  driver: PropTypes.object.isRequired,
  ordersToDispatch: PropTypes.array.isRequired,
  pits: PropTypes.array.isRequired
}

export default function DialogForDispatchWithInstructions(props) {

  const pits = _.map(props.pits, 'name')

  const [pit, setPit] = useState(null)
  const [sequencedOrdersToDispatch, setSequencedOrdersToDispatch] = useState([...props.ordersToDispatch])

  const close = () => {
    props.onClose()
  }

  const onCancel = () => {
    close(false)
  }

  async function dispatchWithInstructions(ignoreSequence) {
    if (!pit) {
      alert('Pit is required for email')
      return
    }
    try {
      const preloadedOrders = await props.orderUtilities.current.getPreloadedOrdersForDateInTableFormatForDriver(new Date(), props.driver)
      await props.orderUtilities.current.batchDispatchWithInstructions(sequencedOrdersToDispatch, props.driver, pit, props.rowColorMap, ignoreSequence, preloadedOrders)
    } catch (error) {
      alert(error.message)
      return
    }
    close(true)
  }

  async function onSend() {
    await dispatchWithInstructions(false)
  }

  async function onSendWithoutSequence() {
    await dispatchWithInstructions(true)
  }

  const handlePitChange = (event, value) => {
    setPit(value ? value.trim() : null)
  }

  const processSequenceChange = (sequencedOrders) => {
    setSequencedOrdersToDispatch(sequencedOrders)
  }

  return (
    <Dialog open={true} onClose={close} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle id="simple-dialog-title">Dispatch Orders To Driver - {props.driver.name}</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="pit"
          size="small"
          fullWidth={true}
          required={true}
          options={pits}
          onChange={handlePitChange}
          value={pit}
          renderInput={(params) => <TextField {...params} label="Pit" InputLabelProps={{ shrink: true }} />}
        />
        <OrderInstructions orders={sequencedOrdersToDispatch} rowColorMap={props.rowColorMap} onSequenceChange={processSequenceChange}></OrderInstructions>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>Cancel</Button>
        <Button color="primary" onClick={onSend} >Send</Button>
        <Button color="primary" onClick={onSendWithoutSequence} >Send Without Sequence</Button>
      </DialogActions>

    </Dialog>
  )



}
