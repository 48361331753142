import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers';
import legend from '../DeliveryLegend.png'
import moment from 'moment';
import _ from 'lodash'

ConvertOrderToDeliver.propTypes = {
  row: PropTypes.object.isRequired,
  orderUtilities: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  driverRefs: PropTypes.array.isRequired
}

export default function ConvertOrderToDeliver(props) {

  const onClose = props.onClose

  const [actualDeliveryDate, setActualDeliveryDate] = useState(moment())
  const [deliveryLocation, setDeliveryLocation] = useState(null)
  const [deliveryNotes, setDeliveryNotes] = useState(null)
  const [payrollLoads, setPayrollLoads] = useState(null)
  const [deliveryAddress, setDeliveryAddress] = useState(props.row.deliveryAddress ? props.row.deliveryAddress : props.row.billToAddress)
  const [ticket, setTicket] = useState(props.row.ticket)
  const [pit, setPit] = useState(props.row.pit)

  const driversMap = useRef(undefined)

  useEffect(() => {
    driversMap.current = _.keyBy(props.driverRefs, (o) => { return o.name } )
  }, [props.driverRefs])


  const close = () => {
    onClose()
  }

  const onCancel = () => {
    close()
  }

  const onConvertToDeliver = async () => {
    const data = { ...props.row }
    delete data.ref
    delete data.tableData
    const truck = driversMap.current[data.driver].truck
    props.orderUtilities.deliverOrder(props.row.ref, data, actualDeliveryDate, truck, deliveryLocation, deliveryAddress, deliveryNotes, ticket, pit, payrollLoads)
    close()
  }

  return (
    <Dialog open={true} onClose={close} disableBackdropClick={true}>
      <DialogTitle id="simple-dialog-title">Deliver</DialogTitle>
      <DialogContent>
        <Grid container justify="center" spacing={2} >
          <Grid item xs={5}>
            <img src={legend} alt="Delivery Legend" />
            <TextField
              value={payrollLoads} 
              onChange={event => setPayrollLoads(event.target.value)} 
              label="Payroll Loads"
            />
          </Grid>
          <Grid item xs={5}>
            <KeyboardDatePicker 
              value={actualDeliveryDate} 
              onChange={date => setActualDeliveryDate(date)} 
              format="MM/DD/YYYY"
              label="Actual Delivery Date"
            />
            <TextField
              value={deliveryLocation} 
              onChange={event => setDeliveryLocation(event.target.value)} 
              label="Location"
            />
            <TextField
              value={deliveryNotes} 
              onChange={event => setDeliveryNotes(event.target.value)} 
              label="Notes"
            />
            <TextField
              value={deliveryAddress} 
              onChange={event => setDeliveryAddress(event.target.value)} 
              label="Delivery Address"
            />
            <TextField
              value={ticket} 
              onChange={event => setTicket(event.target.value)} 
              label="Ticket"
            />
            <TextField
              value={pit} 
              onChange={event => setPit(event.target.value)} 
              label="Pit"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConvertToDeliver} color="primary">
          Deliver
        </Button>
      </DialogActions>
    </Dialog>
  )
}
