import React, {forwardRef, useEffect, useRef, useState } from 'react'
import MaterialTable from 'material-table'
import UserUtilities from '../common/UserUtilities'
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CheckIcon from "@material-ui/icons/Check";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function UserTable(props) {

  const userQueryRef = useRef(undefined)
  const [usersWithRefs, setUsersWithRefs] = useState(undefined)
  const validRoles = [
    'admin',
    'driver'
  ]

  useEffect(() => {
    userQueryRef.current = props.db.collection('users')

    let usersUnsubscribe = userQueryRef.current
      .onSnapshot(querySnapshot => {
        setUsersWithRefs(UserUtilities.performDefaultSortForUsers(querySnapshot.docs))
      })
    return () => {
      usersUnsubscribe()
    }
  }, [props.firebase,
    props.db]
  )

  if (!usersWithRefs) {
    return <div></div>
  }

  return (
    <MaterialTable
      icons={tableIcons}
      title="Users"
      columns={[
        { field: 'ref', hidden: true },
        { title: 'Name', field: 'name'},
        { title: 'Email', field: 'email'},
        { 
          title: 'Roles', 
          field: 'roles',
          initialEditValue: [],
          render: rowData => rowData.roles.join(', '),
          editComponent: prop2 => {
            const { value, onChange } = prop2;
            return (<Select
              multiple={true}
              value={value}
              onChange={(event) => {
                const {
                  target: { value }
                } = event;
                onChange(typeof value === "string" ? value.split(",") : value);
              }}
              input={<Input />}
              renderValue={(selected) => {
                return selected.join(', ')
              }}
            >
              {validRoles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                  {value.includes(role) ? <CheckIcon color="info" /> : null}
                </MenuItem>
              ))}
            </Select>
            )
        }}
      ]}
      data={UserUtilities.convertAllForTable(usersWithRefs)}
      options={{
        filtering: true,
        search: true,
        sorting: true,
        paging: false,
        addRowPosition: 'first'
      }}
      editable={{
        onRowAdd: async newData => {
          const dataWithoutRef = {...newData}
          delete dataWithoutRef.ref
          UserUtilities.add(props.db,dataWithoutRef).catch(err => {
              alert(err)
            })
        },
        onRowUpdate: async (newData, oldData) =>{
          const dataWithoutRef = {...newData}
          delete dataWithoutRef.ref
          UserUtilities.update(props.db, oldData.email, dataWithoutRef).catch(err => {
              alert(err)
            })
        },
      }}
    />
  )

}