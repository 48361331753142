import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { fetchTruckInspection } from '../common/TruckInspectionUtilities'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Image from "material-ui-image"
import { convertFirestoreTimestampToDate } from "../common/FirestoreUtilities"
import { CheckBox } from '@material-ui/icons';

DialogForTruckInspection.propTypes = {
    onClose: PropTypes.func.isRequired,
    db: PropTypes.object.isRequired,
    firebase: PropTypes.object.isRequired,
    truck: PropTypes.string.isRequired,
    truckInspectionId: PropTypes.string.isRequired
}

export default function DialogForTruckInspection(props) {

    const onClose = props.onClose

    const [signatureImageUrl, setSignatureImageUrl] = useState(undefined)
    const [truckInspection, setTruckInspection] = useState(undefined)
    const [inspectionDateAsJSDate, setInspectionDateAsJSDate] = useState(undefined)

    useEffect(async () => {
        setTruckInspection(await fetchTruckInspection(props.db, props.truckInspectionId))
    }, [props.truckInspectionId] )

    useEffect(async () => {
        if (!truckInspection) return
        const storage = getStorage();
        if (truckInspection.signatureFile) {
            setSignatureImageUrl(await getDownloadURL(ref(storage, `signature/${truckInspection.signatureFile}`)))
        } else {
            setSignatureImageUrl(undefined)
        }
        setInspectionDateAsJSDate(convertFirestoreTimestampToDate(props.firebase, truckInspection.inspectionDate))
    }, [truckInspection, inspectionDateAsJSDate])

    if (!truckInspection || !inspectionDateAsJSDate) return (<div></div>)

    return (
        <Dialog open={true} onClose={props.onClose} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle id="simple-dialog-title">Truck Inspection</DialogTitle>
            <DialogContent>
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={4}>
                        <TextField
                            value={inspectionDateAsJSDate.toLocaleString()} 
                            label="TruckInspectionDate"
                            inputProps={{ readOnly: true }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={4}>
                        <TextField
                            value={truckInspection.truck} 
                            label="TRACTOR NUMBER"
                            inputProps={{ readOnly: true }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={4}>
                        <FormGroup>
                            {truckInspection.tractorInspection.map((criteria) => (
                                <span>
                                    {criteria.result && <FormControlLabel control={<Checkbox checked={criteria.result} />} label={criteria.criteria} />}
                                </span>
                            ))}
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={4}>
                        <TextField
                            value={truckInspection.truck} 
                            label="TRAILER NUMBER"
                            inputProps={{ readOnly: true }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={4}>
                        <FormGroup>
                            {truckInspection.trailerInspection.map((criteria) => (
                                <span>
                                    {criteria.result && <FormControlLabel control={<Checkbox checked={criteria.result} />} label={criteria.criteria} />}
                                </span>
                            ))}
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={truckInspection.satisfactoryCondition} />} label={"CONDITION OF THE ABOVE VEHICLES IS SATISFACTORY"} />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={4}>
                        <TextField
                            value={truckInspection.remarks} 
                            label="REMARKS"
                            inputProps={{ readOnly: true }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={4}>
                        { signatureImageUrl &&
                            <Image src={signatureImageUrl} />
                        }
                        { !signatureImageUrl && 
                            <TextField
                                value={"No signature available"} 
                                inputProps={{ readOnly: true }}
                                fullWidth
                            />
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose} >Close</Button>
            </DialogActions>
        </Dialog> 
    )

}