import React from 'react';
import { Icon } from '@iconify/react';
import dumpTruck from '@iconify/icons-mdi/dump-truck';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ClearIcon from '@material-ui/icons/Clear';
import SnoozeIcon from '@material-ui/icons/Snooze';
import houseFill from '@iconify/icons-bi/house-fill';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import splitaltIcon from '@iconify/icons-whh/splitalt';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

import { SvgIcon } from '@material-ui/core'
import SvgAllOrders from '../icons/AllOrders'
import SvgCancelled from '../icons/Cancelled'
import SvgDelivered from '../icons/Delivered'
import SvgDispatched from '../icons/Dispatched'
import SvgFuture from '../icons/Future'
import SvgHeld from '../icons/Held'
import SvgLoadCount from '../icons/LoadCount'
import SvgMetaData from '../icons/MetaData'
import SvgReadyToInvoice from '../icons/ReadyToInvoice'
import SvgRecentOrders from '../icons/RecentOrders'
import SvgReporting from '../icons/Reporting'
import SvgReadyOrders from '../icons/ReadyOrders'
import SvgSplitOrder from '../icons/SplitOrder'

export default class OrdersTableActions {

  constructor(orderUtilities, 
    processReadyAction, 
    processFutureAction,
    processDispatchAction,
    processDeliverAction,
    processSplitAction) {

    const actionDefs = [
      {
        name: 'ready',
        icon: () => (<SvgIcon component={SvgReadyOrders} viewBox="0 0 375 375" />),
        tooltip: 'Make ready to dispatch',
        onClick: (event, rowData) => processReadyAction(rowData)
      },
      {
        name: 'future',
        icon: () => (<SvgIcon component={SvgFuture} viewBox="0 0 1440 1440" />),
        tooltip: 'Set back to future status',
        onClick: (event, rowData) => processFutureAction(rowData)
      },
      {
        name: 'cancel',
        icon: () => (<SvgIcon component={SvgCancelled} viewBox="0 0 1440 1440" />),
        tooltip: 'Cancel this order',
        onClick: (event, rowData) => {
          if (!window.confirm('Are you sure you want to CANCEL this order')) {
            return
          }
          const data = { ...rowData }
          delete data.ref
          delete data.tableData
          orderUtilities.cancelOrder(rowData.ref, data)
        }
      },
      {
        name: 'hold',
        icon: () => (<SvgIcon component={SvgHeld} viewBox="0 0 1440 1440" />),
        tooltip: 'Hold this order',
        onClick: (event, rowData) => {
          if (!window.confirm('Are you sure you want to HOLD this order')) {
            return
          }
          const data = { ...rowData }
          delete data.ref
          delete data.tableData
          orderUtilities.holdOrder(rowData.ref, data)
        }
      },
      {
        name: 'dispatch',
        icon: () => (<SvgIcon component={SvgDispatched} viewBox="0 0 1440 1440" />),
        tooltip: 'Dispatch this order',
        onClick: (event, rowData) => processDispatchAction(rowData)
      },
      {
        name: 'deliver',
        icon: () => (<SvgIcon component={SvgDelivered} viewBox="0 0 1440 1440" />),
        tooltip: 'Mark this order delivered',
        onClick: (event, rowData) => processDeliverAction(rowData)
      },
      {
        name: 'readyToInvoice',
        icon: () => (<SvgIcon component={SvgReadyToInvoice} viewBox="0 0 1440 1440" />),
        tooltip: 'Mark this order ready to invoice',
        onClick: (event, rowData) => {
          if (!window.confirm('Are you sure you want to make this a READY TO INVOICE order')) {
            return
          }      
          const data = { ...rowData }
          delete data.ref
          delete data.tableData
          orderUtilities.markOrderReadyToInvoice(rowData.ref, data)
        }
      },
      {
        name: 'split',
        icon: () => (<SvgIcon component={SvgSplitOrder} viewBox="0 0 375 375" />),
        tooltip: 'Split this order',
        onClick: (event, rowData) => processSplitAction(rowData)
      }
    ]

    this.actionMap = {}
    actionDefs.forEach(actionDef => {
      this.actionMap[actionDef.name] = actionDef
    })

  }

  makeActionList = (desiredActions) => {
    return desiredActions.map(desiredAction => {
      return this.actionMap[desiredAction]
    })
  }

}