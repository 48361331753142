import React, {forwardRef, useEffect, useRef, useState } from 'react'
import MaterialTable from 'material-table'
import { Button } from '@material-ui/core'
import DialogForTruckInspection from './DialogForTruckInspection'
import { convertFirestoreTimestampToDate } from "../common/FirestoreUtilities"

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function TruckInspectionsTabContent(props) {

    const [displayTruckInspection, setDisplayTruckInspection] = useState(undefined)
    const [truckInspections, setTruckInspections] = useState(undefined)
    const truckInspectionQueryRef = useRef(undefined)

    useEffect(() => {
        truckInspectionQueryRef.current = props.db.collection('truck_inspections').where("truck", "==", props.truck)

        let truckInspectinsUnsubscribe = truckInspectionQueryRef.current
          .onSnapshot(querySnapshot => {
            const tempTruckInspectionsWithRefs = querySnapshot.docs.map(truckInspectionRef => {
                const data = truckInspectionRef.data()
                data.inspectionDate = convertFirestoreTimestampToDate(props.firebase, data.inspectionDate)
                return {ref: truckInspectionRef.id, ...data}
            })
            setTruckInspections(tempTruckInspectionsWithRefs)
          })
        return () => {
            truckInspectinsUnsubscribe()
        }
    }, [props.truck])

    function showTruckInspection(rowData) {
        setDisplayTruckInspection(rowData)
    }

    function closeTruckInspection() {
        setDisplayTruckInspection(undefined)
    }
    
    if (displayTruckInspection) {
        return (<DialogForTruckInspection 
          truck={displayTruckInspection.truck}
          truckInspectionId={displayTruckInspection.ref}
          onClose={closeTruckInspection}
          db={props.db}
          firebase={props.firebase}
        />)
    }  
    
    return (
        <MaterialTable
            icons={tableIcons}
            title="Truck Inspections"
            columns={[
                { field: 'ref', hidden: true },
                { 
                    title: 'Inspection Date', 
                    field: 'inspectionDate',
                    type: 'date',
                    customFilterAndSearch: (term, rowData) => {
                        return this.testDateInCustomFilterAndSearch(term, rowData.inspectionDate)
                    },
                    render: rowData => {
                        return <Button variant="contained" color="primary" onClick={() => showTruckInspection(rowData)}>{rowData.inspectionDate.toLocaleString()}</Button>
                    } 
                },
                { title: "Driver", field: 'driver.name'},
                { title: "Mileage", field: 'mileage'},
                { title: "Satisfactory Condition", field: 'satisfactoryCondition', type: "boolean"},
                { title: "Remarks", field: 'remarks' },
            ]}
            data={truckInspections}  
            options={{
                filtering: true,
                search: true,
                sorting: true,
                paging: false,
                addRowPosition: 'first'
            }}
        />
    )

}